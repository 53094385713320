/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.0.0
 */
'use strict';
(function (r, f) { if (typeof define === 'function' && define.amd) { define(['./colibrio-core-base', './colibrio-core-encryption', './colibrio-core-locator', './colibrio-core-publication-base', './colibrio-readingsystem-base'], function (a0, a1, a2, a3, a4) { return f(r, a0, a1, a2, a3, a4); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(r, require('./colibrio-core-base'), require('./colibrio-core-encryption'), require('./colibrio-core-locator'), require('./colibrio-core-publication-base'), require('./colibrio-readingsystem-base')); } else { r.colibrio = r.colibrio || {}; r.colibrio.readingsystem = r.colibrio.readingsystem || {}; r.colibrio.readingsystem.indexengine = f(r, colibrio.core.base, colibrio.core.encryption, colibrio.core.locator, colibrio.core.publication.base, colibrio.readingsystem.base); } }(typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function(__self,__dep__colibrio_core_base,__dep__colibrio_core_encryption,__dep__colibrio_core_locator,__dep__colibrio_core_publication_base,__dep__colibrio_readingsystem_base) { var api = {}; (function() { 'use strict';function m(a){var b=0;return function(){return b<a.length?{done:!1,value:a[b++]}:{done:!0}}}function q(a){var b="undefined"!=typeof Symbol&&Symbol.iterator&&a[Symbol.iterator];return b?b.call(a):{next:m(a)}}function aa(a){for(var b,d=[];!(b=a.next()).done;)d.push(b.value);return d}var ba="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b},t;
if("function"==typeof Object.setPrototypeOf)t=Object.setPrototypeOf;else{var u;a:{var ca={s:!0},v={};try{v.__proto__=ca;u=v.s;break a}catch(a){}u=!1}t=u?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var w=t,x="undefined"!=typeof window&&window===this?this:"undefined"!=typeof global&&null!=global?global:this,y="function"==typeof Object.defineProperties?Object.defineProperty:function(a,b,d){a!=Array.prototype&&a!=Object.prototype&&(a[b]=d.value)};
function z(a,b){if(b){var d=x;a=a.split(".");for(var e=0;e<a.length-1;e++){var f=a[e];f in d||(d[f]={});d=d[f]}a=a[a.length-1];e=d[a];b=b(e);b!=e&&null!=b&&y(d,a,{configurable:!0,writable:!0,value:b})}}
z("Promise",function(a){function b(c){this.b=0;this.g=void 0;this.a=[];var g=this.c();try{c(g.resolve,g.reject)}catch(k){g.reject(k)}}function d(){this.a=null}function e(c){return c instanceof b?c:new b(function(g){g(c)})}if(a)return a;d.prototype.b=function(c){if(null==this.a){this.a=[];var g=this;this.c(function(){g.g()})}this.a.push(c)};var f=x.setTimeout;d.prototype.c=function(c){f(c,0)};d.prototype.g=function(){for(;this.a&&this.a.length;){var c=this.a;this.a=[];for(var g=0;g<c.length;++g){var k=
c[g];c[g]=null;try{k()}catch(l){this.f(l)}}}this.a=null};d.prototype.f=function(c){this.c(function(){throw c;})};b.prototype.c=function(){function c(l){return function(n){k||(k=!0,l.call(g,n))}}var g=this,k=!1;return{resolve:c(this.B),reject:c(this.f)}};b.prototype.B=function(c){if(c===this)this.f(new TypeError("A Promise cannot resolve to itself"));else if(c instanceof b)this.C(c);else{a:switch(typeof c){case "object":var g=null!=c;break a;case "function":g=!0;break a;default:g=!1}g?this.A(c):this.h(c)}};
b.prototype.A=function(c){var g=void 0;try{g=c.then}catch(k){this.f(k);return}"function"==typeof g?this.D(g,c):this.h(c)};b.prototype.f=function(c){this.i(2,c)};b.prototype.h=function(c){this.i(1,c)};b.prototype.i=function(c,g){if(0!=this.b)throw Error("Cannot settle("+c+", "+g+"): Promise already settled in state"+this.b);this.b=c;this.g=g;this.w()};b.prototype.w=function(){if(null!=this.a){for(var c=0;c<this.a.length;++c)h.b(this.a[c]);this.a=null}};var h=new d;b.prototype.C=function(c){var g=this.c();
c.l(g.resolve,g.reject)};b.prototype.D=function(c,g){var k=this.c();try{c.call(g,k.resolve,k.reject)}catch(l){k.reject(l)}};b.prototype.then=function(c,g){function k(p,r){return"function"==typeof p?function(E){try{l(p(E))}catch(F){n(F)}}:r}var l,n,G=new b(function(p,r){l=p;n=r});this.l(k(c,l),k(g,n));return G};b.prototype.catch=function(c){return this.then(void 0,c)};b.prototype.l=function(c,g){function k(){switch(l.b){case 1:c(l.g);break;case 2:g(l.g);break;default:throw Error("Unexpected state: "+
l.b);}}var l=this;null==this.a?h.b(k):this.a.push(k)};b.resolve=e;b.reject=function(c){return new b(function(g,k){k(c)})};b.race=function(c){return new b(function(g,k){for(var l=q(c),n=l.next();!n.done;n=l.next())e(n.value).l(g,k)})};b.all=function(c){var g=q(c),k=g.next();return k.done?e([]):new b(function(l,n){function G(E){return function(F){p[E]=F;r--;0==r&&l(p)}}var p=[],r=0;do p.push(void 0),r++,e(k.value).l(G(p.length-1),n),k=g.next();while(!k.done)})};return b});
z("Promise.prototype.finally",function(a){return a?a:function(b){return this.then(function(d){return Promise.resolve(b()).then(function(){return d})},function(d){return Promise.resolve(b()).then(function(){throw d;})})}});function A(){A=function(){};x.Symbol||(x.Symbol=da)}function B(a,b){this.a=a;y(this,"description",{configurable:!0,writable:!0,value:b})}B.prototype.toString=function(){return this.a};
var da=function(){function a(d){if(this instanceof a)throw new TypeError("Symbol is not a constructor");return new B("jscomp_symbol_"+(d||"")+"_"+b++,d)}var b=0;return a}();function C(){A();var a=x.Symbol.iterator;a||(a=x.Symbol.iterator=x.Symbol("Symbol.iterator"));"function"!=typeof Array.prototype[a]&&y(Array.prototype,a,{configurable:!0,writable:!0,value:function(){return ea(m(this))}});C=function(){}}function ea(a){C();a={next:a};a[x.Symbol.iterator]=function(){return this};return a}
function D(){this.f=!1;this.a=null;this.i=void 0;this.b=1;this.h=0;this.c=null}function H(a){if(a.f)throw new TypeError("Generator is already running");a.f=!0}D.prototype.g=function(a){this.i=a};function I(a,b){a.c={u:b,v:!0};a.b=a.h}D.prototype.return=function(a){this.c={return:a};this.b=this.h};function fa(a){this.a=new D;this.b=a}function ha(a,b){H(a.a);var d=a.a.a;if(d)return J(a,"return"in d?d["return"]:function(e){return{value:e,done:!0}},b,a.a.return);a.a.return(b);return K(a)}
function J(a,b,d,e){try{var f=b.call(a.a.a,d);if(!(f instanceof Object))throw new TypeError("Iterator result "+f+" is not an object");if(!f.done)return a.a.f=!1,f;var h=f.value}catch(c){return a.a.a=null,I(a.a,c),K(a)}a.a.a=null;e.call(a.a,h);return K(a)}function K(a){for(;a.a.b;)try{var b=a.b(a.a);if(b)return a.a.f=!1,{value:b.value,done:!1}}catch(d){a.a.i=void 0,I(a.a,d)}a.a.f=!1;if(a.a.c){b=a.a.c;a.a.c=null;if(b.v)throw b.u;return{value:b.return,done:!0}}return{value:void 0,done:!0}}
function ia(a){this.next=function(b){H(a.a);a.a.a?b=J(a,a.a.a.next,b,a.a.g):(a.a.g(b),b=K(a));return b};this.throw=function(b){H(a.a);a.a.a?b=J(a,a.a.a["throw"],b,a.a.g):(I(a.a,b),b=K(a));return b};this.return=function(b){return ha(a,b)};C();this[Symbol.iterator]=function(){return this}}function ja(a){function b(e){return a.next(e)}function d(e){return a.throw(e)}return new Promise(function(e,f){function h(c){c.done?e(c.value):Promise.resolve(c.value).then(b,d).then(h,f)}h(a.next())})};var L=__dep__colibrio_core_base;var M=__dep__colibrio_core_encryption;var N=__dep__colibrio_readingsystem_base;function O(a,b,d){Array.isArray(a)?b=[]:b||(b={});if("object"===typeof a&&null!==a)for(var e=Object.keys(a),f=0;f<e.length;f++){var h=e[f],c=a[h];null!==c&&"object"===typeof c?b[h]=O(c,b[h],d):"undefined"===typeof c?d&&delete b[h]:b[h]=c}return b};var P=new Uint16Array(1);P[0]=4660;new Uint8Array(P.buffer);function Q(a){this.a=a}Q.prototype.queryDocuments=function(a){return a.execute(this.a)};function R(a,b){this.a=a;this.c=b;this.b=!1}R.prototype.fetchContentBlockTree=function(){var a=this;return this.a.fetchContentBlockTree().catch(function(b){L.Logger.logWarning(b);a.b=!0;return null})};
R.prototype.fetchDocumentIndex=function(){var a=this;return this.fetchContentBlockTree().then(function(b){var d;b?d=b.toSerializableData({createLocators:a.c.contentBlockLocators,recursive:!0}):d=[];b=d;d=a.a.getLocator();var e={inLinearContent:a.a.getSourceContentDocument().isInLinearContent()},f=JSON.stringify(d)+JSON.stringify(b)+JSON.stringify(e)+JSON.stringify(a.b),h=new M.Sha1;h.append(f);f=h.digestToHex();return{locator:d,contentBlocks:b,documentMetadata:e,hasIndexingErrors:a.b,revision:f}})};
R.prototype.fetchDocumentIndexedMetadata=function(){return Promise.resolve({inLinearContent:this.a.getSourceContentDocument().isInLinearContent()})};R.prototype.getLocator=function(){return this.a.getLocator()};R.prototype.getReaderDocument=function(){return this.a};function S(a,b){return L.ColibrioError.call(this,"colibrio.readingsystem.indexengine",a,b)||this}var T=L.ColibrioError;S.prototype=ba(T.prototype);S.prototype.constructor=S;if(w)w(S,T);else for(var U in T)if("prototype"!=U)if(Object.defineProperties){var V=Object.getOwnPropertyDescriptor(T,U);V&&Object.defineProperty(S,U,V)}else S[U]=T[U];S.a=T.prototype;function W(a,b,d){this.a=a;this.c=b;this.b=d?d:a.getSpine().map(function(e){return new R(e,b)})}function ka(a,b){var d=a.b.find(function(e){return e.getReaderDocument()===b});if(!d)throw new S("READER_PUBLICATION_NOT_MOUNTED");return new W(a.a,a.c,[d])}W.prototype.fetchPublicationIndex=function(){var a=this,b=this.b.map(function(d){return d.fetchDocumentIndex()});return Promise.all(b).then(function(d){return la(a,d)})};W.prototype.fetchPublicationIndexedMetadata=function(){return Promise.resolve(X(this))};
W.prototype.getDocumentIndexProviders=function(){return Promise.resolve(this.b.slice())};W.prototype.getPublicationUri=function(){return this.a.getDefaultLocatorUrl()};W.prototype.getReaderPublication=function(){return this.a};
function la(a,b){var d=a.a.getSourcePublication(),e=d.getHashSignature(),f=b.map(function(c){return c.revision}),h=b.some(function(c){return c.hasIndexingErrors});a=X(a);e=JSON.stringify(e)+JSON.stringify(f)+JSON.stringify(h)+JSON.stringify(a);f=new M.Sha1;f.append(e);e=f.digestToHex();return{canonicalUri:d.getHashSignature(),documents:b,hasIndexingErrors:h,publicationMetadata:a,revision:e}}
function X(a){a=a.a.getSourcePublication().getMetadata();return{identifiers:a.getIdentifiers(),titles:a.getTitles(),languages:a.getLanguages()}};function Y(){this.a=[]}Y.prototype.dismountReaderPublication=function(a){var b=this.a.findIndex(function(d){return d.getReaderPublication()===a});0<=b&&this.a.splice(b,1)};Y.prototype.mountReaderPublication=function(a,b){b=O(b,{contentBlockLocators:!1},!1);a=new W(a,b,null);this.a.push(a)};Y.prototype.queryPublications=function(){return new Q(this.a)};
Y.prototype.queryReaderDocument=function(a,b){var d=a.getReaderPublication(),e=this.a.find(function(f){return f.getReaderPublication()===d});if(!e)throw new S("READER_PUBLICATION_NOT_MOUNTED");a=ka(e,a);return b.execute([a])};function Z(a,b,d){this.g=a;this.i=b;this.h=d;this.f=this.a=null;this.b=[];this.c=[]}Z.prototype.fetchPage=function(a){var b=this;if(a<this.b.length)return this.b[a];var d=0<this.b.length?this.b[this.b.length-1]:Promise.resolve(null),e={};for(e.j=this.b.length;e.j<a+1;e={j:e.j},e.j++)d=d.then(function(f){return function(){return ma(b,f.j)}}(e)),this.b.push(d);return d};
function ma(a,b){var d,e;return ja(new ia(new fa(function(f){if(1==f.b){if((a.a&&0<a.a.length||0<a.g.length)&&a.c.length<=a.h){var h=na(a);f.b=1;f={value:h}}else f.b=3,f=void 0;return f}d=a.c.slice(0,a.h);a.c=a.c.slice(a.h);e=null;0<d.length&&(e={resultItems:d,pageIndex:b,hasMorePages:0<a.c.length});return f.return(e)})))}
function oa(a){if(a.f&&a.a&&0<a.a.length){var b={m:a.a.shift(),o:a.f};return Promise.resolve(b)}return 0<a.g.length?(a.f=a.g.shift(),a.f.getDocumentIndexProviders().then(function(d){a.a=d;return oa(a)})):Promise.resolve(null)}
function na(a){return oa(a).then(function(b){if(!b)return!1;var d=b.m;return d.fetchContentBlockTree().then(function(e){if(e){var f=d.getLocator(),h=b.o.getPublicationUri();for(e=new L.TreeNodeWalker(e.getContentBlocks());e.hasNext();){var c=e.next();(c=a.i.getQueryResultItemsByBlock(c,f,h))&&a.c.push.apply(a.c,c instanceof Array?c:aa(q(c)))}}return a.a&&0<a.a.length||0<a.g.length})})};function pa(a,b){this.c=a;this.a=b;a=this.c.value;this.b="string"===typeof a&&/\S/.test(a)?this.c.value:""}
pa.prototype.getQueryResultItemsByBlock=function(a,b,d){var e=this,f=null;a.getAttributes().some(function(h){var c=!1;if(e.b)switch(e.c.matchType){case "CONTAINS":c=0<=h.localName.indexOf(e.b);break;case "STARTS_WITH":c=h.localName.startsWith(e.b);break;case "ENDS_WITH":c=h.localName.endsWith(e.b);break;case "EQUALS":c=h.localName===e.b}var g=!1;if(c&&e.a)switch(e.a.matchType){case "CONTAINS":g=0<=h.value.indexOf(e.a.value);break;case "STARTS_WITH":g=h.value.startsWith(e.a.value);break;case "ENDS_WITH":g=
h.value.endsWith(e.a.value);break;case "EQUALS":g=h.value===e.a.value}return c&&g})&&(f=[{targets:[{contentBlock:a.toSerializableData({createLocators:!0,recursive:!1}),charLength:0,charOffset:0,documentLocator:b,publicationCanonicalUri:d,readerDocumentIndexInSpine:a.getContentBlockTree().getReaderDocument().getIndexInSpine()}]}]);return f};function qa(a){this.a=a;if(!a.attributeNameSelector)throw new S("INVALID_QUERY","attributeNameSelector is required");}qa.prototype.execute=function(a){return new Z(a.slice(),new pa(this.a.attributeNameSelector,this.a.attributeValueSelector),this.a.pageSize||20)};function ra(a){this.a=new RegExp(a.replace(/[.*+?^${}()|[\]\\]/g,"\\$&").trim(),"ig")}
ra.prototype.getQueryResultItemsByBlock=function(a,b,d){var e=null,f="";if(a.getBlockClass()===N.ContentBlockClass.TEXT)f=a.getTextContent();else if(a.getBlockClass()===N.ContentBlockClass.MEDIA){var h=a.getAttributes().find(function(c){return"alt"===c.localName});h&&(f=h.value)}if(f)for(;null!==(h=this.a.exec(f));)e||(e=[]),e.push({targets:[{contentBlock:a.toSerializableData({createLocators:!0,recursive:!1}),charLength:h[0].length,charOffset:h.index,documentLocator:b,publicationCanonicalUri:d,readerDocumentIndexInSpine:a.getContentBlockTree().getReaderDocument().getIndexInSpine()}]});
return e};function sa(a){this.a=a;if(!a.expression)throw new S("INVALID_QUERY","expression is empty");}sa.prototype.execute=function(a){return new Z(a.slice(),new ra(this.a.expression),this.a.pageSize||20)};api={TextDocumentQuery:sa,ElementAttributeDocumentQuery:qa,QueryValueMatchType:{STARTS_WITH:"STARTS_WITH",CONTAINS:"CONTAINS",ENDS_WITH:"ENDS_WITH",EQUALS:"EQUALS"},ReadingSystemIndexEngine:Y,ReadingSystemIndexEngineError:S,ReadingSystemIndexEngineErrorType:{READER_PUBLICATION_NOT_MOUNTED:"READER_PUBLICATION_NOT_MOUNTED",INVALID_QUERY:"INVALID_QUERY"}};var ta=new ArrayBuffer(8);new DataView(ta);new Uint8Array(ta); }).call(__self); return api; }));
