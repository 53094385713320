
    import Vue from 'vue';
    import Component from "vue-class-component";
    import { Prop, Watch } from "vue-property-decorator";
    import { ImageInteractionHandler } from "./ImageInteractionHandler";

    @Component({
        components: {
        }
    })
    export default class ColibrioImageZoom extends Vue {

        @Prop([String])
        imageUrl: string | null = null;

        destroyed: boolean = false;
        handler: ImageInteractionHandler | undefined = undefined;
        imageElement: HTMLImageElement | undefined = undefined;

        setupImageManipulationHandler() {
            if (!this.destroyed && this.imageElement) {

                let containerElement = this.$refs.containerElement as HTMLElement;
                containerElement.appendChild(this.imageElement);

                this.handler = new ImageInteractionHandler(containerElement, this.imageElement);
            }
        }

        @Watch('imageUrl')
        onImageUrlChanged(imageUrl: string | null) {
            if (this.handler) {
                this.handler.destroy();
            }
            if (this.imageElement) {
                this.unregisterImageLoadListeners();
                let parentNode = this.imageElement.parentNode;
                if (parentNode) {
                    parentNode.removeChild(this.imageElement);
                }
                this.imageElement = undefined;
            }

            if (imageUrl) {
                this.imageElement = document.createElement('img') as HTMLImageElement;
                this.imageElement.src = imageUrl;
                if (this.imageElement.complete) {
                    this.setupImageManipulationHandler()
                } else {

                    this.imageElement.onload = () => {
                        this.unregisterImageLoadListeners();
                        this.setupImageManipulationHandler();
                    };
                    this.imageElement.onabort = () => this.unregisterImageLoadListeners();
                    this.imageElement.onerror = () => this.unregisterImageLoadListeners();
                }
            }
        }

        unregisterImageLoadListeners() {
            if (this.imageElement) {
                this.imageElement.onload = null!;
                this.imageElement.onabort = null!;
                this.imageElement.onerror = null!;
            }
        }

        beforeDestroy() {
            if (this.handler) {
                this.handler.destroy();
                this.handler = undefined;
            }
        }

    }

