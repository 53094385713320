/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import './lib/colibrio-publishing-framework/colibrio-polyfill-dom';
import './lib/colibrio-publishing-framework/colibrio-polyfill-es6';
import './lib/colibrio-publishing-framework/colibrio-polyfill-geometry';
import './lib/colibrio-publishing-framework/colibrio-polyfill-webanimation';
import 'material-design-icons-iconfont';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import 'vuetify/dist/vuetify.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css'
import './index.scss';
import App from './App.vue';
import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import Vuetify from 'vuetify'
import {IosNativePinchZoomPreventer} from "./utils/platform-workarounds/IosNativePinchZoomPreventer";
import {BrowserDetector} from "./lib/colibrio-publishing-framework/colibrio-core-base";
//import VueCompositionAPI from '@vue/composition-api'
import router from './router'

// @ts-ignore
const moment = require('moment');

Vue.filter('dateFormat', (value: any) => {
    return moment.utc(value).local().format('h:mm A DD MMM YYYY')
})

Vue.filter('singleDateFormat', (value: any) => {
    if (value === null) {
        return  " - ";
    }

    return moment.utc(value).local().format('DD MMM YYYY')
})

Vue.filter('singleTimeFormat', (value: any) => {

    if (value === null) {
        return  " - ";
    }

    return moment(value, 'h:mm A').format('h:mm A')
})

Vue.filter('checkUndefined', (value: any) => {
    return value !== undefined ? value : ' - ';
})


Vue.use(BootstrapVue);
Vue.use(Vuetify, {
    iconfont: 'md',
    theme: {
        primary: '#d75828',
        secondary: '#d75828',
        accent: '#d75828',
        error: '#d75828',
        info: '#d75828',
        success: '#d75828',
        warning: '#d75828'
    },
    customProperties: true
});

if (document.body) {
    init();
} else {
    document.addEventListener('DOMContentLoaded', init, false);
}


function init() {
    if (BrowserDetector.isOS('iOS')) {
        let iosPinchZoomPreventer = new IosNativePinchZoomPreventer();
        iosPinchZoomPreventer.observe();
    }

    // @ts-ignore
    window.vm = new Vue({
        router,
        data: {
            sharedState: {}
        },
        render: h => h(App)
    }).$mount("#demo-app");
}


const iframe = document.createElement('iframe');
iframe.setAttribute('allow', 'fullscreen'); // must be 1st
iframe.setAttribute('allowFullScreen', '');
