//require('dotenv').config();

export const BASE_URL = process.env.API_BASE_URL !== undefined ? process.env.API_BASE_URL : 'https://test-laravel.test'

export const BOOKMARK_LIST = BASE_URL + '/api/v1/book-mark/list'
export const BOOKMARK_CREATE = BASE_URL + '/api/v1/book-mark/save'
export const BOOKMARK_DELETE = BASE_URL + '/api/v1/book-mark'

export const CHAPTERS_LIST = BASE_URL + '/api/v1/chapter/list'
export const CHAPTER_META_SAVE = BASE_URL + '/api/v1/chapter-meta/save'

export const GET_CHAPTER_TIMELINE = BASE_URL + '/api/v1/get-timeline'

