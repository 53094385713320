/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import { IRandomAccessDataSource } from '../../../lib/colibrio-publishing-framework/colibrio-core-io-base';
import { HttpRequest } from './HttpRequest';

export class HttpDataSource implements IRandomAccessDataSource {

    constructor(private url: string, private size: number) {
    }

    /**
     * Fetch a chunk of data from the data source between a start and end offset.
     * This method must not throw an exception in case of a non-recoverable error.
     *
     * @param startOffset - An index into the data source indicating the first byte to include in the result buffer
     * @param endOffset - An index into the data source indicating the first byte that will *not* be included in the result buffer. Must be larger than 'start', and less than or equal to the value returned by getSize().
     * @return Resolved with an ArrayBuffer containing the requested data. Rejected if it is not possible to fetch the requested data.
     */
    fetchChunk(startOffset: number, endOffset: number): Promise<ArrayBuffer> {
        return HttpRequest.sendRangeRequest(this.url, startOffset, endOffset - 1);
    }

    /**
     * Get the size of the data in bytes.
     */
    getSize(): number {
        return this.size;
    }

}



