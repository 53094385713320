
import Vue from 'vue';
import Component from "vue-class-component";
import {Watch} from "vue-property-decorator";
import {readerModel} from '../../../model/ReaderModel';
import {GlobalEventBus} from '../../../GlobalEventBus';
import {GlobalEventName} from '../../../GlobalEventName';
import {
  IReaderPublicationNavigationCollection,
  IReaderPublicationNavigationItem,
  IReaderPublicationNavigationItemReference,
  NavigationCollectionType
} from "../../../lib/colibrio-publishing-framework/colibrio-readingsystem-base";
import {ColibrioPublicationNavItem} from "./ColibrioPublicationNavItem";
import {ColibrioPublicationNavCollection} from "./ColibrioPublicationNavCollection";
import {IPublicationMetadataItem} from "../../../lib/colibrio-publishing-framework/colibrio-core-publication-base";
import {ITreeViewItemSourceData, TreeView} from "../../../lib/aria-web-components/TreeView";
import ColibrioPublicationSearchPanel from "../search-panel/colibrio-publication-nav-search-panel.vue";

@Component({
  components: {
    ColibrioPublicationSearchPanel
  }
})
export default class ColibrioPublicationNav extends Vue {
  navCollections: ColibrioPublicationNavCollection[] = [];
  publicationTitle: string = '';
  publicationMetadata: IColibrioMetadataTreeItem[] = [];
  showClosePublicationDialog = false;
  navigationTreeItems: any[] = [];
  navigationTree: TreeView | undefined = undefined;
  navigationTreeItemsTypeMap: Map<IReaderPublicationNavigationItem, ColibrioPublicationNavItem> = new Map();
  navigationTreeItemsActive: string[] = [];

  navigationTreeFilterString = null;

  appDialogsSetAriaHiddenAttribute(value: string) {
    let appDialogElements = document.querySelectorAll('.v-dialog__content');
    if (appDialogElements) {
      for (let i = 0; appDialogElements.length > i; i++) {
        appDialogElements[i].setAttribute('aria-hidden', value);
      }
    }
  }

  @Watch('publicationTitle')
  onPropertyChanged(value: string) {
    // Do stuff with the watcher here.
    this.$emit('get-publication-title', value);
  }

  @Watch('navCollections')
  onNavCollections(value: string) {
    // Do stuff with the watcher here.
    this.$emit('nav-collection-change', value);
  }

  mounted() {

    this.appDialogsSetAriaHiddenAttribute('true');

    let readingSystem = readerModel.getReadingSystem();

    if (!readingSystem) {
      console.error('ReadingSystem not set!');
      return;
    }
    let readerPublication = readingSystem.getReaderPublications()[0];
    let sourcePublication = readerPublication.getSourcePublication();
    let metadataItems = sourcePublication.getMetadata().getAll();
    this.publicationMetadata.push({
      id: '',
      name: 'Publication Metadata',
      children: [],
      content: {
        dir: null,
        lang: 'en-EN',
        value: ''
      },
      property: {
        name: '',
        namespace: ''
      },
      contentScheme: null,
      refinedBy: []
    });

    this.publicationMetadata[0].children = metadataItems.map((item: IPublicationMetadataItem, index) => {
      let treeItem = (item as IColibrioMetadataTreeItem);
      if (!treeItem.id) {
        treeItem.id = 'metadata_item_' + index;
      }
      treeItem.children = [];
      return treeItem;
    });

    let titleObj = readerPublication.getSourcePublication().getMetadata().getTitles()[0];

    if (titleObj) {
      this.publicationTitle = titleObj.content.value || '';
    }


    readingSystem.addEngineEventListener('visibleRangeChanged', async (event) => {

      const visibleRange = event.readerView.getVisibleRange();
      if (!visibleRange) {
        return;
      }

      const navigationItemRefsResult = await visibleRange.fetchNavigationItemReferences({greedy: true});

      navigationItemRefsResult.getItemsInRange().forEach((reference: IReaderPublicationNavigationItemReference) => {
        this.navigationTreeItemsActive.push(reference.getNavigationItem().getId().toString());
        this.activeItemByNavItemReference(reference);
      });

    });

    GlobalEventBus.$on(GlobalEventName.APP_NAV_DRAWER_OPENED, this.onDrawerOpened);
    this.navigationTreeCreateFromNavItems();
  }


  async navigationTreeCreateFromNavItems() {
    let readerPublication = await readerModel.getReadingSystem().getReaderPublications()[0];

    readerPublication.fetchPublicationNavigation().then(navDocument => {

      // Transform the navigation collection from the reader to a view model object, where we can add more properties required for our view.
      let readerNavCollections = navDocument.getNavigationCollections();
      readerNavCollections.forEach(readerNavCollection => {
        let children: ColibrioPublicationNavItem[] | undefined = undefined;
        let childItems = readerNavCollection.getChildren() as IReaderPublicationNavigationItem[]
        if (childItems) {
          children = this.createColibrioPublicationNavItems(childItems) as ColibrioPublicationNavItem[];
          let collectionTitle = this.getNavCollectionTitle(readerNavCollection);
          let collectionId = collectionTitle.replace(/\s/g, '') + '_rootNode';
          this.navigationTreeItems.push({textContent: collectionTitle, children: children, id: collectionId});
        }
      });

      this.navCollections = readerNavCollections.map(readerNavCollection => {
        let children: ColibrioPublicationNavItem[] | null = null;
        let childCollection = readerNavCollection.getChildren() as IReaderPublicationNavigationItem[];
        if (childCollection) {
          children = this.createColibrioPublicationNavItems(childCollection)
        }

        let navCollection: ColibrioPublicationNavCollection = {
          children: children,
          expanded: readerNavCollection.getType() === NavigationCollectionType.TOC, // Let TOC be expanded by default
          title: readerNavCollection.getTitle() || '',
          type: readerNavCollection.getType()
        };
        return navCollection;
      });

      if (this.navCollections[0] !== undefined) {
        let tocNavCollection = this.navCollections[0].children;

        if (tocNavCollection) {
          /*let treeItems = this.navigationTreeBuildTree(tocNavCollection);
          this.navigationTree = new TreeView<ColibrioPublicationNav>(((this.$refs.navDrawer as Vue).$el as HTMLElement), treeItems);
          this.navigationTree.render();
          this.navigationTree.addItemClickEventHandler((navItem: ITreeViewItemSourceData) => {
            this.goToNavItem(navItem);
          });*/
        }
      }


    });
  }

  navigationTreeBuildTree(items: ColibrioPublicationNavItem[]): ITreeViewItemSourceData[] {
    let tree: ITreeViewItemSourceData<ColibrioPublicationNavItem>[] = [];

    items.forEach((item) => {

      let hasChildren = item.children && item.children.length > 0;
      let treeViewItemData: ITreeViewItemSourceData = {
        id: item.id,
        textContent: item.textContent,
        data: item,
        children: hasChildren ? this.navigationTreeBuildBranch(item) : [],
      };

      tree.push(treeViewItemData);
    });

    return tree;
  }

  navigationTreeBuildBranch(item: ColibrioPublicationNavItem): ITreeViewItemSourceData[] {
    let branch: ITreeViewItemSourceData[] = [];
    if (item.children) {

      item.children.forEach((childItem: ColibrioPublicationNavItem) => {
        let hasChildren = childItem.children && childItem.children.length > 0;

        let treeViewItemData: ITreeViewItemSourceData = {
          id: childItem.id,
          textContent: childItem.textContent,
          data: childItem,
          children: hasChildren ? this.navigationTreeBuildBranch(childItem) : [],
        };

        branch.push(treeViewItemData);
      });
    }

    return branch;
  }

  destroy() {
    this.publicationMetadata = [];
    this.navCollections = [];
    this.navigationTreeItemsTypeMap.clear();
  }

  close() {
    GlobalEventBus.$emit(GlobalEventName.APP_NAV_DRAWER_CLOSE_INTENT);
  }

  getNavCollectionTitle(navCollection: IReaderPublicationNavigationCollection): string {
    if (navCollection.getTitle()) {
      return navCollection.getTitle() as string;
    }

    switch (navCollection.getType()) {
      case NavigationCollectionType.TOC:
        return 'Table of Contents';

      case NavigationCollectionType.LANDMARKS:
        return 'Landmarks';

      case NavigationCollectionType.PAGE_LIST:
        return 'Page list';

      case NavigationCollectionType.LIST_OF_VIDEO_CLIPS:
        return 'Video clips';

      case NavigationCollectionType.LIST_OF_AUDIO_CLIPS:
        return 'Audio clips';

      case NavigationCollectionType.LIST_OF_ILLUSTRATIONS:
        return 'Illustrations';

      case NavigationCollectionType.LIST_OF_TABLES:
        return 'Tables';
    }

    return 'Other links'
  }

  goToNavItem(childNavItem: ITreeViewItemSourceData) {
    if (childNavItem.data.hasOwnProperty('locator')) {
      //@ts-ignore
      GlobalEventBus.$emit(GlobalEventName.APP_NAV_DRAWER_NAV_ITEM_CLICKED, childNavItem.data.locator);
    }
  }

  onClosePublicationClicked() {
    this.onClosePublicationConfirmed();
  }

  onClosePublicationCancelled() {
    this.showClosePublicationDialog = false;

  }

  onClosePublicationConfirmed() {
    this.close();
    GlobalEventBus.$emit(GlobalEventName.READER_PUBLICATION_CLOSE);
  }

  private activeItemByNavItemReference(reference: IReaderPublicationNavigationItemReference) {
    if (this.navigationTree) {
      let readerNavItem = reference.getNavigationItem();

      let navItem = this.navigationTreeItemsTypeMap.get(readerNavItem);
      if (navItem) {
        let treeViewItem = this.navigationTree?.getTreeItemByItemSourceData(navItem);
        if (treeViewItem) {
          this.navigationTree.activeItem = treeViewItem;
          treeViewItem.setIsActive(true);
        }
      }
    }
  }

  private createColibrioPublicationNavItems(readerItems: IReaderPublicationNavigationItem[]): ColibrioPublicationNavItem[] {
    return readerItems.map(readerItem => {

      let children: ColibrioPublicationNavItem[] = [];
      let childItems = readerItem.getChildren() as IReaderPublicationNavigationItem[];
      if (childItems) {
        children = this.createColibrioPublicationNavItems(childItems);
      }

      let navItem: ColibrioPublicationNavItem = {
        active: false,
        id: readerItem.getId().toString(),
        locator: readerItem.getLocator(),
        children: children,
        expanded: false,
        textContent: readerItem.getTextContent()
      };

      this.navigationTreeItemsTypeMap.set(readerItem, navItem);
      return navItem;
    });
  }

  private onDrawerOpened() {
    let searchPanel = (this.$refs.searchPanel as any);

    if (searchPanel && searchPanel.searchTerm === '') {
      if (this.navigationTree?.activeItem) {
        this.navigationTree?.expandToTreeItem(this.navigationTree?.activeItem);
        this.navigationTree?.activeItem.setIsActive(true, true);

      }
    }
  }
}

export interface IColibrioMetadataTreeItem extends IPublicationMetadataItem {
  id: string;
  name: string;
  children: any[];
}

