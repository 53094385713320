

import Vue from "vue";
import Component from "vue-class-component";
import {readerModel} from '../../../model/ReaderModel';
import {Emit, Prop, Watch} from "vue-property-decorator";
import {GlobalEventBus} from '../../../GlobalEventBus';
import {GlobalEventName} from '../../../GlobalEventName';

import {
  ContainerContentBlockType,
  IContentBlock,
  IContentBlockMarkData,
  IContentLocation,
  ICustomReaderViewContent,
  IEngineEventMediaResource,
  IMouseEngineEvent,
  INavigationIntentEngineEvent,
  IPointerEngineEvent,
  IReaderDocument,
  IReaderPublication, IReaderPublicationNavigationItemReference,
  IReaderView,
  IReaderViewAnnotationLayer,
  ISelectionChangedEngineEvent,
  ISyncMediaTimeline,
  ISyncMediaTimelinePosition,
  ISyncMediaTimelinePositionData,
  MediaContentBlockType,
  NavigationCollectionType,
  PageProgressionTimelineType,
  ReaderDocumentEventState,
  ReaderViewPageProgressionDirection,
  SyncMediaFormat,
  TextContentBlockType,
} from "../../../lib/colibrio-publishing-framework/colibrio-readingsystem-base";
import {
  FlipBookRenderer,
  SinglePageSwipeRenderer,
  StackRenderer
} from "../../../lib/colibrio-publishing-framework/colibrio-readingsystem-renderer";
import {
  ResponsiveViewRule,
  SyncMediaTimelinePosition
} from '../../../lib/colibrio-publishing-framework/colibrio-readingsystem-engine';
import {
  BrowserDetector,
  ColibrioError,
  IAttributeData,
  KeyCode,
  Logger,
  TreeNodeWalker
} from '../../../lib/colibrio-publishing-framework/colibrio-core-base';

import {ContentDocumentLayout,} from "../../../lib/colibrio-publishing-framework/colibrio-core-publication-base";
import {MediaType, MediaTypeCategory} from "../../../lib/colibrio-publishing-framework/colibrio-core-io-base";
import {IEpubReaderPublication} from "../../../lib/colibrio-publishing-framework/colibrio-readingsystem-formatadapter-epub";
import {createFocusTrap} from 'focus-trap';
import {ILocator, ISimpleLocatorData} from "../../../lib/colibrio-publishing-framework/colibrio-core-locator";
import {IFragmentSelector} from "../../../lib/colibrio-publishing-framework/colibrio-core-selector-base";
import ColibrioImageZoom from "../image-zoom/colibrio-image-zoom.vue";
import ColibrioLoader from "../../colibrio-loader/colibrio-loader.vue";
import {IPdfReaderPublication} from "../../../lib/colibrio-publishing-framework/colibrio-readingsystem-formatadapter-pdf";

let readerType: string | null = null;

if (window.URLSearchParams && document.location.search) {
  let urlParams = new URLSearchParams(document.location.search);
  readerType = urlParams.get('reader_type') ? decodeURIComponent(urlParams.get('reader_type')!) : null;
}

@Component({
  components: {
    ColibrioImageZoom,
    ColibrioLoader
  }
})
export default class ColibrioPublicationView extends Vue {

  currentPageNumbers = '';
  hasExactPageNumbers = false;
  hasPageList = false;

  /**
   * This is our main view for reading the linear content of the Publication
   */
  mainView: IReaderView | undefined;

  mainViewTimelineValue: number | null = null;
  mainViewTimelineLength: number | null = null;

  /**
   * Circumvents a vue/vuetify bug that causes @change event to be emitted twice per click.
   */
  mainViewTimelineLastChangeValue: number = -1;

  /**
   * This reader view is used for reading non-linear content activated by clicking on links in the Publication
   */
  popupReaderView: IReaderView | undefined;

  externalHref: string | null = null;
  appNonlinearDocumentDialogVisible: boolean = false;
  appExternalHrefConfirmDialogVisible: boolean = false;
  appImageZoomDialogVisible: boolean = false;

  @Prop()
  resizingDisabled!: boolean;

  @Prop()
  initialLocation!: string;

  @Prop()
  currentLocationHash!: string;

  @Prop()
  fileType!: string;

  @Prop()
  activeTabIndex: number;

  appZoomedImageUrl: string | null = null;
  revokeZoomedImageUrl: (() => void) | undefined = undefined;

  syncMediaViewAnnotationLayer: IReaderViewAnnotationLayer | undefined = undefined;
  syncMediaSupported: boolean = true;
  syncMediaPlayerFocusTrap: any;
  syncMediaPlayerControlsVisible: boolean = true;
  syncMediaPlayerPlaying: boolean = false;
  syncMediaPlayerMuted: boolean = false;
  syncMediaPlayerPlaybackVolume: number = 70;
  syncMediaVolumeDialogVisible: boolean = false;
  syncMediaVolumeDialogFocusTrap: any;
  syncMediaPlayerPlaybackRate: number = 100;
  syncMediaRateDialogVisible: boolean = false;
  syncMediaRateDialogFocusTrap: any;
  syncMediaTempPauseTimeoutId: number | null = null;
  syncMediaPlayerWaiting: boolean = false;
  syncMediaPageTurnTimeoutId: number | null = null;
  syncMediaHighlightColor: string = 'yellow';

  timelinePromise: Promise<ISyncMediaTimeline | null> | undefined = undefined;
  visiblePagesHaveSyncMedia: boolean = false;
  annotationLayer: IReaderViewAnnotationLayer | undefined = undefined;

  zoomModeActive: boolean = false;
  lastPointerDownEvent: IPointerEngineEvent | undefined = undefined;
  lastPointerUpEvent: IPointerEngineEvent | undefined = undefined;
  timelineEscapePosition: ISyncMediaTimelinePositionData | undefined = undefined;
  hasTimelineEscapePosition = false;

  navDrawerOpen = false;
  settingsDrawerOpen = false;
  appAriaNotificationMessage: string = '';

  // While a goTo() navigation is in process this variable
  // will hold a reference to the INavigationItemTarget representing the location. Once the navgation has
  // succeeded or failed it is set to null;
  currentNavigationTarget: ILocator | undefined = undefined;

  clientIsSafari = BrowserDetector.isBrowser("Safari");
  clientIsIOS = BrowserDetector.isOS("iOS");
  clientIsAndroid = BrowserDetector.isOS("Android");
  // clientIsMac = BrowserDetector.isOS("MacOS");
  // clientIsChromeOS = BrowserDetector.isOS("ChromeOS");
  clientIsMobile = BrowserDetector.isPlatform("mobile");
  clientIsTablet = BrowserDetector.isPlatform("tablet");
  clientIsDesktop = BrowserDetector.isPlatform("desktop");

  contentDocumentsLandmarks: Map<IReaderDocument, IContentDocumentData> | undefined = undefined;
  visibleContentDocumentLandmarks: IContentDocumentData[] | null = null;
  loadedContentDocumentLandmarks: IContentDocumentData[] | null = null;
  appPublicationLandmarksDialogVisible: boolean = false;
  appPublicationLandmarksDialogFocusTrap: any | undefined = undefined;
  appColibrioSpeechPlaybackOnly: boolean = false;
  isSample: boolean = false;
  activeChapterName: string = ''
  pageNo: string = this.currentLocationHash.split('=')[1]

  @Watch('appPublicationLandmarksDialogVisible')
  onAppPublicationLandmarksDialogVisibilityChange(visible: boolean, oldValue: boolean) {
    if (visible === oldValue) return;
    let dialog = ((this.$refs.appPublicationLandmarksDialog as Vue).$el as HTMLElement);
    if (dialog) {
      if (visible) {
        this.appDialogsSetAriaHiddenAttribute('false');
        this.appPublicationLandmarksDialogFocusTrap = this.appPublicationLandmarksDialogFocusTrap || createFocusTrap(dialog, {
          clickOutsideDeactivates: true,
          escapeDeactivates: true,
          returnFocusOnDeactivate: true,
          initialFocus: dialog
        });
        this.appPublicationLandmarksDialogFocusTrap.activate();
      } else {
        this.appDialogsSetAriaHiddenAttribute('true');
        this.appPublicationLandmarksDialogFocusTrap.deactivate();
      }
    }
  }

  @Emit()
  toggleNavDrawer() {
  }

  @Emit()
  toggleSettingsDrawer() {
  }

  @Watch('currentLocationHash')
  onPageNoPropChange(value: string) {
    console.log(value)
    this.pageNo = value.split('=')[1]
  }

  @Watch('syncMediaVolumeDialogVisible')
  onSyncMediaVolumeDialogVisibilityChange(visible: boolean, oldValue: boolean) {

    if (visible === oldValue) return;

    if (visible) {
      window.setTimeout(() => {
        let dialog = ((this.$refs.syncMediaVolumeDialog as Vue).$el as HTMLElement);
        this.appDialogsSetAriaHiddenAttribute('false');
        let dialogInput = dialog.querySelector('select')!;
        dialogInput.focus();
        this.syncMediaVolumeDialogFocusTrap = this.syncMediaVolumeDialogFocusTrap || createFocusTrap(dialog, {
          clickOutsideDeactivates: true,
          escapeDeactivates: true,
          returnFocusOnDeactivate: false
        });
        this.syncMediaVolumeDialogFocusTrap.activate();
      }, 500);
    } else {
      this.syncMediaVolumeDialogFocusTrap.deactivate();
      this.appDialogsSetAriaHiddenAttribute('true');
      this.$nextTick(() => {
        let button = ((this.$refs.syncMediaPlayerVolumeDialogButton as Vue).$el as HTMLElement);
        if (button) {
          button.focus();
        }
      });
    }
  }

  @Watch('syncMediaRateDialogVisible')
  onSyncMediaRateDialogVisibilityChange(visible: boolean, oldValue: boolean) {

    if (visible === oldValue) return;

    let vueDialogElement = ((this.$refs.syncMediaRateDialog as Vue).$el as HTMLElement);

    if (visible) {
      window.setTimeout(() => {
        this.appDialogsSetAriaHiddenAttribute('false');
        let dialogInput = vueDialogElement.querySelector('select')!;
        let decreaseButton = vueDialogElement.querySelector('.v-input__icon--prepend i') as HTMLElement;
        if (decreaseButton) {
          decreaseButton.setAttribute('tabindex', '0');
          decreaseButton.removeAttribute('aria-hidden');
          decreaseButton.setAttribute('aria-label', 'decrease');
          decreaseButton.setAttribute('role', 'button');

          decreaseButton.addEventListener('keydown', (ev: KeyboardEvent) => {
            if (ev.keyCode === 13 || ev.keyCode === 32) {
              this.syncMediaPlayerPlaybackRateDecrease();
            }
          });
        }

        let increaseButton = vueDialogElement.querySelector('.v-input__icon--append i') as HTMLElement;
        if (increaseButton) {
          increaseButton.setAttribute('tabindex', '0');
          increaseButton.removeAttribute('aria-hidden');
          increaseButton.setAttribute('aria-label', 'decrease');
          increaseButton.setAttribute('role', 'button');

          increaseButton.addEventListener('keydown', (ev: KeyboardEvent) => {
            if (ev.keyCode === 13 || ev.keyCode === 32) {
              this.syncMediaPlayerPlaybackRateIncrease();
            }
          });
        }

        dialogInput.focus();

        this.syncMediaRateDialogFocusTrap = this.syncMediaRateDialogFocusTrap || createFocusTrap(vueDialogElement, {
          clickOutsideDeactivates: true,
          escapeDeactivates: true,
          returnFocusOnDeactivate: false
        });
        this.$nextTick(() => {
          this.syncMediaRateDialogFocusTrap.activate();
        });
      }, 500);

    } else {
      this.appDialogsSetAriaHiddenAttribute('true');
      this.syncMediaRateDialogFocusTrap.deactivate();
      this.$nextTick(() => {
        let button = ((this.$refs.syncMediaPlayerRateDialogButton as Vue).$el as HTMLElement);
        if (button) {
          button.focus();
        }
      });
    }
  }

  @Watch('activeTabIndex')
  onActiveTabIndexChange(visible: number) {
    if (visible === 1) {
      this.syncMediaHighlightColorChange('cornflowerblue')
    } else {
      this.syncMediaHighlightColorChange('transparent')
    }

  }
  @Watch('activeChapterName')
  onActiveChapterNameChange(chapter: string) {
    this.$emit('active-chapter-name', chapter);

  }

  /**
   * Setup our mainView and configure the different renderers we will support.
   */
  createMainView() {

    let readingSystem = readerModel.getReadingSystem();
    if (!readingSystem) {
      console.error('ReadingSystem not set!');
      return;
    }
    let readerViewElement = this.$refs.readerViewElement as HTMLElement;

    /*
     * You can load several publications simultaneously with a single reading system.
     * In this demo implementation there is always one publication.
     */
    let readerPublication = readingSystem.getReaderPublications()[0];

    /*
     * We use a View instance to render our main content.
     * The View is a "manager" for Renderers and makes it easy for us to
     * select a Renderer implementation depending on the device screen size and orientation.
     *
     * By default, the view will be in "responsive" mode and select renderer based on which renderer that can use as much as possible of the available
     * target element area.
     *
     * For example, the view may use the FlipBookRenderer when orientation is landscape,
     * and StackRenderer when orientation is portrait.
     */
    this.mainView = readingSystem.createReaderView({

      name: 'mainView',
      pageProgressionTimelineOptions: {
        delayRecalculationUntilPagesVisible: true,
        forceCompleteRendition: false
      }

    });

    /*
     * The FlipBookRenderer is the "bookish" renderer where 2 pages are displayed side-by-side
     */
    let flipbookRenderer = new FlipBookRenderer({
      name: 'flipbookRenderer',
      disableAnimations: false
    });


    /*
     * When we add the renderer to mainView, we can also pass a ResponsiveViewRule that acts as a filter when the view selects which renderer to use.
     * The ReaderViewport rule takes a media query string, or a function callback.
     *
     * In this case we tell the view to use this renderer only if the min-width is 600px.
     */
    this.mainView.addRenderer(flipbookRenderer, new ResponsiveViewRule('(min-width: 600px)'));

    let stackRenderer = new StackRenderer({
      name: 'stackRenderer',
      disableAnimations: false
    });

    this.mainView.addRenderer(stackRenderer, new ResponsiveViewRule('(min-width: 300px)'));

    /*
     * Sometimes it is useful to create several renderer instances of the same type.
     * On this StackRenderer instance, we set the option { ignoreAspectRatio: true }
     * and use a ResponsiveViewRule to specifically target small screens.
     * (We also have a @media query in the vue CSS in this file to hide the previous/next arrows in the app)
     *
     * The result is that for small screens, we will use as much as possible of the device screen for displaying the publication.
     *
     * let stackViewIgnoreAspectRatio = new StackRenderer({
     *      ignoreAspectRatio: true,
     *      name: 'stackRendererIgnoreAspectRatio'
     * });
     * this.mainView.addRenderer(stackViewIgnoreAspectRatio, new ResponsiveViewRule('(min-width: 300px) and (max-width: 450px)'));
     */

    /*
     * Here we tell the view which content documents it should render.
     * You have full control of which documents to render into a view, and which order to render them.
     *
     * We can re-order the documents in the array to present them in another order:
     * - readerDocuments = readerDocuments.filter(myFilter).reverse()
     *
     * You can even mix content documents from multiple publications:
     * - readerDocuments = readerPublication1.getSpine().concat(readerPublication2.getSpine())
     *
     * In the code below we filter out any document which is set as non-linear.
     * Non-linear content documents are shown in a popup view instead. See goToDocumentContentLocation() method
     * for this implementation.
     */
    let status = true;
    let readerDocuments;
    if (readerPublication.getSourcePublication().getMediaType() === MediaType.APPLICATION_PDF) {
      readerDocuments = readerPublication.getSpine().filter((readerDocument) => {
        return readerDocument.getSourceContentDocument().isInLinearContent();
      });
    } else {
      readerDocuments = readerPublication.getSpine().filter((readerDocument) => {
        let fileLimit = 'chapter04';
        let hideFiles = ['halftitle', 'titlepage', 'copyrightpage', 'dedication', 'acknowledgements', 'introduction']
        let documentDetails = readerDocument.getSourceContentDocument().getContentUrl();
        let pathArray = documentDetails.pathname.split('/');
        if (pathArray[3] !== undefined) {
          let fileName = pathArray[3].split('.')[0];
          if (!hideFiles.includes(fileName)) {
            console.log(pathArray[3].split('.'));
            if (documentDetails.pathname.includes(fileLimit)) { status = false }
            if (documentDetails.pathname.includes("bm01")) { status = true }
            return readerDocument.getSourceContentDocument().isInLinearContent() && status;
          }
        }

      });
    }

    this.mainView.setReaderDocuments(readerDocuments);


    /**
     * Now lets setup custom page content for the various IView.setViewContent*() methods.
     */

    /**
     * If the viewport is to small, lets ariaNotify the user
     */
    this.mainView.setContentOnActiveRendererMissing('<p>Viewport too small</p>');

    /**
     * Uncomment the following line to set your own loader
     * this.mainView.setViewContentOnLoading('<h1>Loading...</h1>');
     **/

    /**
     * When the reading system injects empty pages to obey left/right spread slot. Show this text.
     */
    this.mainView.setContentOnEmptyPage('<p>This page is intentionally left blank</p>');

    /**
     * If there was a problem loading publication content, ariaNotify the user and let her retry.
     */
    let refreshCallback = () => {
      if (this.mainView) {
        this.mainView.refresh(true);
      }
    };
    let errorPage: ICustomReaderViewContent = {
      renderTo(element: HTMLElement) {
        element.innerHTML = `
                        <p>An error occurred while loading this page :(</p>
                        <button>Retry</button>
                    `;

        element.querySelector('button')!.addEventListener('click', refreshCallback);
      },

      onRemoved(element: HTMLElement) {
        element.querySelector('button')!.removeEventListener('click', refreshCallback);
      }
    };
    this.mainView.setContentOnLoadError(errorPage);

    /*
     * Set which DOM Element the mainView should render to.
     * It is up to you to set the width and height of this element. mainView will not expand/style this element.
     *
     * In this demo, we have styled the readerViewElement using flex-box so that its size is responsive..
     */
    this.mainView.renderTo(readerViewElement);

    GlobalEventBus.$emit(GlobalEventName.READER_VIEW_CREATED, this.mainView.getName());

    /*
     * We haven't told mainView where we want to start reading yet, so at the moment the view will not render anything.
     *
     * Calling goToStart() will tell the view to go to the first page of the first document in the list of documents we passed in the call above.
     *
     * The colibrio publishing framework uses annotation targets extensively for targeting content in publications.
     *
     * If we wanted to continue reading from the last session, we would call readerView.goTo(lastReadingPosition) where lastReadingPosition is an ISimpleLocatorData object;
     * The current reading position can be retrieved anytime by calling this.readerView.getCurrentAnnotationTarget()
     */
    if (this.initialLocation) {
      let startAnnotationTarget: ISimpleLocatorData = {
        sourceUrl: this.mainView.getReaderPublications()[0].getDefaultLocatorUrl(),
        selectors: [this.initialLocation.slice(1)]
      };
      if (!this.goTo(startAnnotationTarget)) {
        this.mainView.goToStart().catch(err => {
          if (!ColibrioError.isColibrioAbortedError(err)) {
            console.error(err);
          }
        });
      }

    } else {
      this.mainView.goToStart().catch(err => {
        if (!ColibrioError.isColibrioAbortedError(err)) {
          console.error(err);
        }
      });
    }

    // Use publication page-list if available to show the page numbers from a printed edition.
    readerPublication.fetchPublicationNavigation().then(publicationNavigation => {
      let pageListCollection = publicationNavigation.getNavigationCollections().find(collection => collection.getType() === NavigationCollectionType.PAGE_LIST);
      if (pageListCollection && pageListCollection.getChildren()) {
        this.hasPageList = true;
      }
    });

    this.syncMediaSupported = readerPublication.getSourcePublication().getMediaType() === MediaType.APPLICATION_EPUB_ZIP ||
        readerPublication.getSourcePublication().getMediaType() === MediaType.APPLICATION_PDF;


  }

  /**
   * Setup all event handling with our view.
   */
  setupEventListeners() {
    let readingSystem = readerModel.getReadingSystem();

    if (!this.mainView || !readingSystem) {
      return;
    }


    /*
     * Normally, when a link is clicked in the Publication, the view will go to the content targeted by that link,
     * but only if that link points to a content documents we've told the view to render.
     *
     * In our case, non-linear content documents are not rendered into the readerView, so those links won't work.
     * Also clicks on links which points to external resources will not work either.
     *
     * Fortunately, each time a link is clicked in the Publication, Colibrio Reader SDK will generate a ReaderEvent with type 'navigationIntent'
     * to let us specify what should happen.
     *
     * We can attach an event listener directly on the readerView to handle events generated specifically by that view,
     * or we can attach a listener on the readingSystem to receive events from all reader views.
     *
     * In this demo we have both a main view (readerView) and a view for reading non-linear content (popupReaderView)
     * so lets attach the listener to the readingSystem to handle them in one single place.
     */
    readingSystem.addEngineEventListener<'navigationIntent'>('navigationIntent', (navigationIntentEngineEvent: INavigationIntentEngineEvent) => {
      // Calling preventDefault() will prevent Colibrio Reader SDKs default behaviour for the navigationIntent event.
      navigationIntentEngineEvent.preventDefault();

      let pointerDeltaY = 0;
      let pointerDeltaX = 0;

      if (this.lastPointerDownEvent && this.lastPointerUpEvent) {
        pointerDeltaX = Math.abs(this.lastPointerUpEvent.screenX - this.lastPointerDownEvent.screenX);
        pointerDeltaY = Math.abs(this.lastPointerUpEvent.screenY - this.lastPointerDownEvent.screenY);
      }
      if (pointerDeltaY > 20 || pointerDeltaX > 20) {
        // Pointer has been dragged during pointer down, this is probably a pan gesture so we exit.
        return;
      }

      if (this.zoomModeActive && navigationIntentEngineEvent.readerView.getTransformManager().getActiveTransform()?.scale === 1) {
        // If the user has pressed the "Zoom" button, the next click should always perform scaling.
        return;
      }
      /*
       * The navigationIntentEvent contains the property readerDocumentLocationTarget
       * which contains information about which content document the user wishes to navigate to.
       * It also contains lots of internal publication-specific data about where in the document to go to.
       *
       * It will be null if this is an externalNavigation, for example to another website.
       */
      let documentLocationTarget = navigationIntentEngineEvent.readerDocument?.getContentLocation();
      let internalNavigation = navigationIntentEngineEvent.internalNavigation;
      let locator = navigationIntentEngineEvent.locator;

      if (internalNavigation && documentLocationTarget) {
        this.goTo(locator);

      } else if (!internalNavigation && navigationIntentEngineEvent.locator.getSourceUrl()) {

        /*
         * Most engine events generated due to user input, such as mouse clicks, still fires asynchronously as the original target resides in an iframe with separate origin.
         * Due to this, most browsers will prevent open new tabs/windows if we try it inside this callback.
         *
         * We get around this limitation by asking the user if he/she wants to navigate to the specified URL using a dialog in the app.
         * When the user clicks anything in the app dialog, a "trusted" event will be fired and we can open a new tab/window.
         */
        this.showOpenUrlDialog(navigationIntentEngineEvent.locator.getSourceUrl());
      }
    });

    this.mainView.addEngineEventListener<'pointerdown'>('pointerdown', event => {
      this.lastPointerDownEvent = event;
      GlobalEventBus.$emit(GlobalEventName.READER_PUBLICATION_POINTER_DOWN, event);
    });

    this.mainView.addEngineEventListener<'pointerup'>('pointerup', event => {
      this.lastPointerUpEvent = event;
      GlobalEventBus.$emit(GlobalEventName.READER_PUBLICATION_POINTER_UP, event);
    });

    this.mainView.addEngineEventListener<'navigationEnded'>('navigationEnded', _event => {
      // Only move focus to the book if the TTS or MO is not playing, and if no modal dialog is open.
      if (!this.syncMediaPlayerPlaying && !this.viewIsInModalState()) {
        this.focusOnReadingPosition(500);
      }
    });

    this.mainView.addEngineEventListener<'pageProgressionTimelineRecalculated'>('pageProgressionTimelineRecalculated', evt => {
      let pageProgressionTimeline = evt.readerView.getPageProgressionTimeline();
      if (pageProgressionTimeline) {
        let currentSegment = pageProgressionTimeline.getVisibleTimelineRange();
        this.mainViewTimelineLength = pageProgressionTimeline.getTotalNumberOfPages();

        if (currentSegment.end.pageIndex === this.mainViewTimelineLength! - 1) {
          // If we are on the last page, make sure the progressbar is filled.
          this.mainViewTimelineValue = currentSegment.end.pageIndex + 1;
        } else {
          this.mainViewTimelineValue = currentSegment.start.pageIndex + 1;
        }


        if (!this.hasPageList && pageProgressionTimeline.getTimelineType() !== PageProgressionTimelineType.ESTIMATED_PAGES) {
          this.hasExactPageNumbers = true;
          if (currentSegment.start !== currentSegment.end) {
            this.currentPageNumbers = `${currentSegment.start.pageIndex + 1} - ${currentSegment.end.pageIndex + 1}`;
          } else {
            this.currentPageNumbers = '' + (currentSegment.start.pageIndex + 1);
          }
        }
      } else {
        this.mainViewTimelineLength = null;
        this.mainViewTimelineValue = null;
      }
    });

    this.mainView.addEngineEventListener('visiblePagesChanged', async (evt) => {
      this.mainViewTimelineLastChangeValue = -1;
      let pageProgressionTimeline = evt.readerView.getPageProgressionTimeline();
      if (pageProgressionTimeline) {
        let currentSegment = pageProgressionTimeline.getVisibleTimelineRange();
        this.mainViewTimelineLength = pageProgressionTimeline.getTotalNumberOfPages();

        if (currentSegment.end.pageIndex === this.mainViewTimelineLength! - 1) {
          // If we are on the last page, make sure the progressbar is filled.
          this.mainViewTimelineValue = currentSegment.end.pageIndex + 1;
        } else {
          this.mainViewTimelineValue = currentSegment.start.pageIndex + 1;
        }
      }

      GlobalEventBus.$emit(GlobalEventName.READER_PUBLICATION_VISIBLE_PAGES_CHANGED, evt);

      const visibleRange = evt.readerView.getVisibleRange();
      if (!visibleRange) {
        return;
      }

      const navigationItemRefsResult = await visibleRange.fetchNavigationItemReferences({greedy: true});

      navigationItemRefsResult.getItemsInRange().forEach((reference: IReaderPublicationNavigationItemReference) => {
        this.activeChapterName = reference.getNavigationItem().getTextContent()
      });

      this.populateContentDocumentsLandmarks().then(() => {
        this.$nextTick(() => {
          this.loadedContentDocumentLandmarks = this.getLandmarksForLoadedDocuments();
          this.visibleContentDocumentLandmarks = this.getLandmarksForVisibleDocuments();
        });
      });
    });

    this.mainView.addEngineEventListener<'syncMediaPlay'>('syncMediaPlay', _evt => {
      this.syncMediaPlayerPlaying = true;
    });

    this.mainView.addEngineEventListener<'syncMediaPaused'>('syncMediaPaused', _evt => {
      this.syncMediaPlayerPlaying = false;
      if (this.syncMediaPageTurnTimeoutId) {
        window.clearTimeout(this.syncMediaPageTurnTimeoutId);
        this.syncMediaPageTurnTimeoutId = null;
      }
    });

    this.mainView.addEngineEventListener<'syncMediaReady'>('syncMediaReady', _evt => {
      this.syncMediaPlayerWaiting = false;
    });

    this.mainView.addEngineEventListener<'syncMediaWaiting'>('syncMediaWaiting', _evt => {
      this.syncMediaPlayerWaiting = true;
    });

    this.mainView.addEngineEventListener('click', evt => {
      this.$emit("pointer-clicked", evt)


      if (this.lastPointerDownEvent) {
        // Some browsers, send click events even though the pointer moves before the up event.
        // So we track how many pixels the pointer has moved since last pointer down to see if this is a real click event.
        let eventDeltaX = Math.abs(evt.screenX - this.lastPointerDownEvent.screenX);
        let eventDeltaY = Math.abs(evt.screenY - this.lastPointerDownEvent.screenY);

        if (eventDeltaX > 20 || eventDeltaY > 20) {
          // The pointer had moved to far during the pointer down/move event. Let's
          // treat this as a pan event and return.
          return;
        }
      }

      GlobalEventBus.$emit(GlobalEventName.READER_PUBLICATION_CLICK, evt);
      let activeTransform = evt.readerView.getTransformManager().getActiveTransform();
      let isZoomed: boolean = false;
      if (activeTransform) {
        isZoomed = activeTransform.scale ? activeTransform.scale > 1 : false;
      }
      if (this.zoomModeActive) {
        if (!isZoomed) {
          this.zoomToPointerPosition(evt, 2);
        }
      } else if (
          evt.mediaResource &&
          evt.mediaResource.getMediaTypeCategory() === MediaTypeCategory.IMAGE &&
          evt.readerDocumentEventState === ReaderDocumentEventState.NOT_PROCESSED &&
          (evt.target && evt.target.contentLocation?.getReaderDocuments()[0] && evt.target.contentLocation?.getReaderDocuments()[0].getSourceContentDocument().getLayout() === ContentDocumentLayout.REFLOWABLE) &&
          (!this.lastPointerUpEvent || this.lastPointerUpEvent.readerDocumentEventState === ReaderDocumentEventState.NOT_PROCESSED) &&
          this.appZoomedImageUrl === null
      ) {
        let mediaResource = evt.mediaResource;
        this.appImageZoomDialogShow(mediaResource);
      }

    });

    this.mainView.addEngineEventListener('visibleContentRendered', (evt) => {

      let loaderOverlay = document.querySelector('.colibrio-renderer-loader');
      if (loaderOverlay) {
        loaderOverlay.setAttribute('aria-hidden', 'true');
      }

      if (!this.navDrawerOpen && !this.settingsDrawerOpen) {
        //this.publicationIframeSetFocus();
      }

      if (!this.currentNavigationTarget) {
        // If we have no currentNavigationTarget the navigation was probably triggered by a previous/next
        // in which case we have not set the currentNavigationTarget variable
        let readingPosition = this.mainView!.getReadingPosition();
        this.currentNavigationTarget = readingPosition?.getLocator();

      }

      this.currentNavigationTarget = undefined;

      GlobalEventBus.$emit(GlobalEventName.READER_PUBLICATION_VISIBLE_PAGES_RENDERED, evt)

    });

    document.documentElement.addEventListener('keyup', this.onKeyUp);
    document.documentElement.addEventListener('keydown', this.onKeyDown);

    /*
     * Listen on "resize" events so we can scale the viewport and re-layout publication contents.
     *
     * Since there are several "resize" scenarios where re-layout is unwanted,
     * Colibrio Reader SDK doesn't automatically re-layout publications on resize.
     *
     * For example, clicking on a "Search" input field on a mobile device will bring up soft-keyboard which will trigger a resize event.
     * In this case we probably do not want to re-layout the contents..
     */
    window.addEventListener('resize', this.onResize);

    this.mainView.addEngineEventListener('selectionChanged', (ev: ISelectionChangedEngineEvent) => {
      if (this.mainView) {
        const mediaPlayer = this.mainView.getSyncMediaPlayer();
        if (mediaPlayer && ev.contentLocation && !ev.isRange) {

          let timeline = mediaPlayer.getTimeline();
          timeline.fetchTimelinePosition(ev.contentLocation.getLocator()).then((pos: ISyncMediaTimelinePosition) => {
            // Always seek to beginning of segment by setting offsetMs to 0
            let newPosition = new SyncMediaTimelinePosition(pos.getTimeline(), pos.getSegmentIndex(), 0);
            mediaPlayer.seekToTimelinePosition(newPosition);
          });

        }
      }
    });

    /*
     * We use a global Vue event-bus for various events in the app.
     */
    GlobalEventBus.$on(GlobalEventName.APP_NAV_DRAWER_NAV_ITEM_CLICKED, this.onNavItemClicked);
    GlobalEventBus.$on(GlobalEventName.READER_RENDERER_CHANGE_INTENT, this.changeRenderer);
    GlobalEventBus.$on(GlobalEventName.READER_VIEW_GOTO_FRAGMENT_SELECTOR, this.goToFragmentSelector);
    GlobalEventBus.$on(GlobalEventName.APP_NAV_DRAWER_OPENED, this.onAppNavDrawerOpened);
    GlobalEventBus.$on(GlobalEventName.APP_NAV_DRAWER_CLOSED, this.onAppNavDrawerClosed);
    GlobalEventBus.$on(GlobalEventName.APP_NAV_DRAWER_CLOSE_INTENT, this.onAppNavDrawerClosed);
    GlobalEventBus.$on(GlobalEventName.APP_SETTINGS_DRAWER_OPENED, this.onAppSettingsDrawerOpened);
    GlobalEventBus.$on(GlobalEventName.APP_SETTINGS_DRAWER_CLOSED, this.onAppSettingsDrawerClosed);
    GlobalEventBus.$on(GlobalEventName.APP_SETTINGS_DRAWER_CLOSE_INTENT, this.onAppSettingsDrawerClosed);
    GlobalEventBus.$on(GlobalEventName.APP_SYNC_MEDIA_SPEECH_VOLUME_CHANGE_INTENT, this.syncMediaPlayerVolumeChange);
    GlobalEventBus.$on(GlobalEventName.APP_SYNC_MEDIA_SPEECH_RATE_CHANGE_INTENT, this.syncMediaPlayerPlaybackRateChange);
    GlobalEventBus.$on(GlobalEventName.APP_SYNC_MEDIA_HIGHLIGHT_COLOR_CHANGE_INTENT, this.syncMediaHighlightColorChange);
    GlobalEventBus.$on(GlobalEventName.APP_SETTINGS_NARRATION_MODE_CHANGE_INTENT, this.onAppNarrationModeChange);
  }

  destroyed() {
    document.documentElement.removeEventListener('keyup', this.onKeyUp);
    document.documentElement.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('resize', this.onResize);

    GlobalEventBus.$off(GlobalEventName.APP_NAV_DRAWER_NAV_ITEM_CLICKED, this.onNavItemClicked);
    GlobalEventBus.$off(GlobalEventName.READER_RENDERER_CHANGE_INTENT, this.changeRenderer);
    GlobalEventBus.$off(GlobalEventName.READER_VIEW_GOTO_FRAGMENT_SELECTOR, this.goToFragmentSelector);
    GlobalEventBus.$off(GlobalEventName.APP_NAV_DRAWER_OPENED, this.onAppNavDrawerOpened);
    GlobalEventBus.$off(GlobalEventName.APP_NAV_DRAWER_CLOSED, this.onAppNavDrawerClosed);
    GlobalEventBus.$off(GlobalEventName.APP_SETTINGS_DRAWER_OPENED, this.onAppSettingsDrawerOpened);
    GlobalEventBus.$off(GlobalEventName.APP_SETTINGS_DRAWER_CLOSED, this.onAppSettingsDrawerClosed);

    this.appNonlinearDocumentDialogDestroy();
    this.appImageZoomDialogDestroy();
    if (this.mainView) {
      // Destroying the view will also remove all added engine event listeners.
      let engine = this.mainView.getReadingSystemEngine();
      engine.destroyReaderView(this.mainView);
      this.mainView = undefined;
    }
    this.timelinePromise = undefined;

    this.lastPointerDownEvent = undefined;
    this.lastPointerUpEvent = undefined;
    this.timelineEscapePosition = undefined;

    this.contentDocumentsLandmarks!.clear();
    this.contentDocumentsLandmarks = undefined;

    this.visibleContentDocumentLandmarks = null;

  }

  onKeyDown(ev: KeyboardEvent) {
    let elementName: string = (ev.target as HTMLElement).localName as string;
    // Make it less likely that navigation key events interfere with voice over technologies by checking for active modifier keys.
    let modifierActive: boolean = (ev.getModifierState('Control') || ev.getModifierState('Alt') || ev.getModifierState('Meta') || ev.getModifierState('CapsLock') || ev.getModifierState('Fn') || ev.getModifierState('OS'));
    if (this.mainView && !ev.defaultPrevented && (elementName !== 'textarea' && elementName !== 'input')) {

      if (ev.keyCode === KeyCode.KEY_RIGHT && !modifierActive && !this.navDrawerOpen && !this.settingsDrawerOpen) {
        this.navigateToRight();
      } else if (ev.keyCode === KeyCode.KEY_LEFT && !modifierActive && !this.navDrawerOpen && !this.settingsDrawerOpen) {
        this.navigateToLeft();
      }

      if (ev.keyCode === KeyCode.KEY_ESC && !modifierActive) {
        GlobalEventBus.$emit(GlobalEventName.APP_EVENT_KEYUP_ESC, ev.target);
      }

    }
  }

  onKeyUp(_ev: KeyboardEvent) {
    // Make it less likely that navigation key events interfere with voice over technologies by checking for active modifier keys.
    //let modifierActive: boolean = (ev.getModifierState('Control') || ev.getModifierState('Alt') || ev.getModifierState('Meta') || ev.getModifierState('CapsLock') || ev.getModifierState('Fn') || ev.getModifierState('OS'));
  }

  onResize(_ev: UIEvent) {
    if (this.mainView) {
      this.mainView.refresh();
    }
    if (this.popupReaderView) {
      this.popupReaderView.refresh();
    }
  };

  onDocumentLandmarkClicked(annotationTarget: ISimpleLocatorData) {
    this.appPublicationLandmarksDialogVisible = false;
    GlobalEventBus.$emit(GlobalEventName.APP_DIALOG_LANDMARKS_CLOSED);
    this.goTo(annotationTarget);
  }

  changeRenderer(rendererName: string) {
    if (this.mainView) {
      if (rendererName === '__responsive') {
        this.mainView.setResponsiveRendererSelectionEnabled(true);
      } else {
        let view = this.mainView.getRendererByName(rendererName);
        if (view) {
          this.mainView.setActiveRenderer(view);
        }
      }
    }
  }

  onNavItemClicked(target: ILocator) {
    this.goTo(target);
  }

  /**
   * What we receive is an plain locator object (which has the same schema as "target" in the Web annotations spec.).
   *
   * We could just sent this object to readerView.goTo(), but we resolve it against the readerPublication to get a documentLocationTarget object instead.
   * We need this object to know if the target content document is a non-linear content document that should be opened in the popup reader.
   */
  goTo(locator: ILocator | ISimpleLocatorData): boolean {
    let readerPublication = readerModel.getReadingSystem().getReaderPublications()[0];
    if (readerPublication) {
      readerPublication.fetchUnresolvedContentLocation(locator).then(unresolvedLocation => {
        unresolvedLocation.resolve().then(
            (contentLocation) => {
              this.goToDocumentContentLocation(contentLocation);
            }
        )
      }).catch(err => {
        if (!ColibrioError.isColibrioAbortedError(err)) {
          Logger.logError(err);
        }
        return false;
      });
    }
    return true;
  }

  goToDocumentContentLocation(contentLocation: IContentLocation) {
    if (contentLocation.getReaderDocuments()[0].getSourceContentDocument().isInLinearContent()) {
      if (this.popupReaderView || this.appNonlinearDocumentDialogVisible) {
        this.appNonlinearDocumentDialogDestroy();
      }

      if (this.mainView) {
        this.mainView?.goTo(contentLocation.getLocator());
      }

      GlobalEventBus.$emit(GlobalEventName.READER_VIEW_GOTO, contentLocation);

    } else {
      this.showDocumentInPopupReaderView(contentLocation);
    }
  }

  goToFragmentSelector(fragmentSelector: string) {
    let readerPublication = readerModel.getReadingSystem().getReaderPublications()[0];
    let selector: ISimpleLocatorData = {
      sourceUrl: readerPublication.getDefaultLocatorUrl(),
      selectors: [fragmentSelector]
    };

    if (this.mainView) {
      this.goTo(selector);
    }
  }

  focusOnReadingPosition(_timeoutMs: number): void {

    this.mainView?.focusOnReadingPosition({
      focusOnPageContainer: false,
      focusOnPageBodyElement: false,
      focusNearContentLocation: true
    });
  }

  fetchSyncMediaTimeline(ttsAnnotationLayer?: IReaderViewAnnotationLayer): Promise<ISyncMediaTimeline | null> {
    if (!this.timelinePromise) {
      if (!this.mainView) {
        return Promise.reject('this.mainView not set :(');
      }

      let mainView = this.mainView;

      // Try to fetch the publication native syncMedia timeline first.
      let readerPublication = mainView.getReaderPublications()[0];

      if (isEpubReaderPublication(readerPublication)) {
        let hasSyncMedia = readerPublication.getAvailableSyncMediaFormats().find((format) => format === SyncMediaFormat.EPUB_MEDIA_OVERLAY)
        if (hasSyncMedia) {
          this.timelinePromise = readerPublication.createMediaOverlaySyncMediaTimeline(mainView.getReaderDocuments()).then(timeline => {
            return timeline || (readerPublication as IEpubReaderPublication).createTtsSyncMediaTimeline(readerPublication.getSpine());
          });
        } else {
          this.timelinePromise = readerPublication.createTtsSyncMediaTimeline(readerPublication.getSpine(), {defaultTtsHighlightLayer: ttsAnnotationLayer});
        }
      } else {
        this.timelinePromise = (readerPublication as IPdfReaderPublication).createTtsSyncMediaTimeline(readerPublication.getSpine(), {defaultTtsHighlightLayer: ttsAnnotationLayer});
      }

      this.timelinePromise?.catch(err => {
        console.error(err);
        this.timelinePromise = undefined; // Unset so we retry next time.
      });
    }
    return this.timelinePromise;
  }

  appDialogsSetAriaHiddenAttribute(value: string) {
    let appDialogElements = document.querySelectorAll('.v-dialog__content');
    if (appDialogElements) {
      for (let i = 0; appDialogElements.length > i; i++) {
        appDialogElements[i].setAttribute('aria-hidden', value);
      }
    }
  }

  reSizeFunction () {
    if (window.innerWidth < 790) {
      document.getElementById("read-more-button").style.setProperty('display', 'block', 'important')
    } else {
      document.getElementById("read-more-button").style.setProperty('display', 'none', 'important')
    }
    return true;
  }


  /**
   * Vue.js life-cycle hook.
   * This method is called by Vue after this component has been attached to the DOM.
   */
  mounted() {

    document.getElementById("read-more-button").style.setProperty('display', 'block', 'important')

    this.reSizeFunction();
    window.addEventListener('resize', () => {
      if (window.innerWidth < 790) {
        document.getElementById("read-more-button").style.setProperty('display', 'block', 'important')
      } else {
        document.getElementById("read-more-button").style.setProperty('display', 'none', 'important')
      }
    });



    this.appDialogsSetAriaHiddenAttribute('true');

    setTimeout(() => {
      this.syncMediaHighlightColorChange('transparent')
    }, 2000)


    if (readerType !== undefined && readerType !== null) {
      this.isSample = true;
    }

    this.createMainView();
    this.setupEventListeners();
    this.contentDocumentsLandmarks = new Map<IReaderDocument, IContentDocumentData>();
    this.visibleContentDocumentLandmarks = [];
    this.syncMediaPlayerCreate();
  }

  navigateToLeft() {
    if (this.viewIsInModalState()) return;

    if (this.mainView && this.mainView.getPageProgressionDirection() === ReaderViewPageProgressionDirection.LTR) {
      this.previous();
    } else {
      this.next();
    }
  }

  navigateToRight() {

    if (this.viewIsInModalState()) return;

    if (this.mainView && this.mainView.getPageProgressionDirection() === ReaderViewPageProgressionDirection.LTR) {
      this.next()
    } else {
      this.previous();
    }
  }

  onProgressBarChanged(value: number) {
    // For some reason, this method is fired twice by vue. First with the old value, then with the new value.
    if (this.mainView && value !== this.mainViewTimelineLastChangeValue && this.mainViewTimelineValue !== null) {
      // Wierdness in Edge cause a bug in Vuetify when pressing left/right arrow while the slider element has focus.
      if (document.activeElement) {
        (document.activeElement as HTMLElement).blur();
      }
      this.mainViewTimelineLastChangeValue = value;
      let timeline = this.mainView.getPageProgressionTimeline();
      if (timeline) {
        let pageValue = value - 1;
        let currentSegment = timeline.getVisibleTimelineRange();
        if (pageValue < currentSegment.start.pageIndex || pageValue > currentSegment.end.pageIndex) {
          timeline.fetchLocatorFromPosition({
            pageIndex: pageValue,
            offset: currentSegment.start.offset
          }).then(locator => {
            if (this.mainView && this.mainView.canPerformGoTo()) {
              return this.goTo(locator);
            }
          }).catch(err => {
            if (!ColibrioError.isColibrioAbortedError(err)) {
              Logger.logError(err);
            }
          });
        }
      }
    }
  }

  previous() {
    if (this.mainView && this.mainView.canPerformPrevious()) {
      GlobalEventBus.$emit(GlobalEventName.READER_VIEW_GOTO_PREVIOUS);
      this.mainView.previous().then(() => {
      }).catch(err => {
        if (!ColibrioError.isColibrioAbortedError(err)) {
          Logger.logError(err);
        }
      });
    } else {
      const queryParams = this.$route.query;
      if (queryParams.book_id !== undefined) {
        this.$emit('jumpToPrev');
      }
    }
  }

  next() {
    if (this.mainView && this.mainView.canPerformNext()) {
      GlobalEventBus.$emit(GlobalEventName.READER_VIEW_GOTO_NEXT);
      this.mainView.next().then(() => {
      }).catch(err => {
        if (!ColibrioError.isColibrioAbortedError(err)) {
          Logger.logError(err);
        }
      });
    } else {
      const queryParams = this.$route.query;
      if (queryParams.book_id !== undefined) {
        this.$emit('jumpToNext');
      }
    }
  }

  appOpenExternalHref() {
    if (this.externalHref) {
      window.open(this.externalHref, '_blank');
    }
    this.appExternalHrefConfirmDialogVisible = false
  }

  showOpenUrlDialog(href: string) {
    let dialog = (this.$refs.appExternalHrefConfirmDialog as Vue).$el as HTMLElement;
    if (dialog) {
      dialog.setAttribute('aria-hidden', 'true');
    }
    this.externalHref = href;
    this.appExternalHrefConfirmDialogVisible = true;
  }

  appCloseOpenUrlConfirmDialog() {
    let dialog = (this.$refs.appExternalHrefConfirmDialog as Vue).$el as HTMLElement;
    if (dialog) {
      dialog.removeAttribute('aria-hidden');
    }
    this.appExternalHrefConfirmDialogVisible = false;
    this.ariaNotify('Dialog closed');
  }

  showDocumentInPopupReaderView(documentLocationTarget: IContentLocation) {
    let publicationNonlinearDocumentElement = this.$refs.publicationNonlinearDocumentElement as HTMLElement;
    let readingSystem = readerModel.getReadingSystem();
    if (this.popupReaderView) {
      // The popupReaderView is already open
      this.popupReaderView.setReaderDocuments([documentLocationTarget.getReaderDocuments()[0]]);
      this.popupReaderView.renderTo(publicationNonlinearDocumentElement);
    } else if (readingSystem) {

      // Trigger Vue to show the dialog.
      this.appNonlinearDocumentDialogVisible = true;

      /*
       * We use a scroll view for the popup reader view.
       * We also set "allowNativePanY" which is important to allow the browser to use native-scroll.
       */
      let popupReaderView = readingSystem.createReaderView();
      popupReaderView.setActiveRenderer(new SinglePageSwipeRenderer());
      popupReaderView.setReaderDocuments([documentLocationTarget.getReaderDocuments()[0]]);

      // Unfortunately, Vuetify does not emit any events when the dialog is actually rendered to the DOM. For now we just go with setTimeout.
      setTimeout(() => {
        // Could get here in a racing condition i.e. two non-linear content documents opening at the same time.
        if (!this.popupReaderView && this.appNonlinearDocumentDialogVisible) {
          this.popupReaderView = popupReaderView;
          this.popupReaderView.renderTo(publicationNonlinearDocumentElement);
          this.popupReaderView.goTo(documentLocationTarget).catch(err => {
            if (!ColibrioError.isColibrioAbortedError(err)) {
              Logger.logError(err);
            }
          });

          this.popupReaderView.focusOnReadingPosition({
            focusOnPageContainer: true,
            focusOnPageBodyElement: true,
            focusNearContentLocation: true
          });
        }
      }, 200);
    }
  }

  appImageZoomDialogDestroy() {
    if (this.revokeZoomedImageUrl) {
      this.revokeZoomedImageUrl();
      this.revokeZoomedImageUrl = undefined;
    }
    this.appZoomedImageUrl = null;
    this.appImageZoomDialogVisible = false;

    this.ariaNotify('Image fullscreen viewer closed.');
    this.focusOnReadingPosition(500);
  }

  appNonlinearDocumentDialogDestroy() {
    if (this.popupReaderView) {
      let engine = this.popupReaderView.getReadingSystemEngine();
      engine.destroyReaderView(this.popupReaderView);
      this.popupReaderView = undefined;
    }
    this.appNonlinearDocumentDialogVisible = false;
  }


  toggleZoomMode() {
    this.zoomModeActive = !this.zoomModeActive;
    if (this.mainView) {

      if (this.zoomModeActive) {

        this.mainView.setOptions({
          gestureOptions: {
            panZoom: {
              maxPanOffsetHorizontal: '0%',
              maxPanOffsetVertical: '0%',
              allowSinglePointerPan: true,
              pointerTypes: {
                pen: true,
                mouse: true,
                touch: true
              }
            }
          }
        });
        //this.mainView.setViewState();
      } else {

        this.mainView.setOptions({
          gestureOptions: {
            panZoom: {
              maxPanOffsetHorizontal: '0%',
              maxPanOffsetVertical: '0%',
              allowSinglePointerPan: false,
              pointerTypes: {
                pen: false,
                mouse: false,
                touch: false
              }
            }
          }
        });
        //this.mainView.setViewState(ViewState.TRANSFORM);
      }
    }
  }

  zoomToPointerPosition(evt: IMouseEngineEvent, scale = 2) {
    if (this.mainView) {
      // this.mainView.setViewState(ViewState.TRANSFORM);
      let transformManager = this.mainView.getTransformManager();
      if (transformManager && transformManager.canTransform()) {
        transformManager.zoomToEventPosition(evt, scale);
      }
    }
  }


  syncMediaPlayerCreate(): void {
    if (!this.mainView) {
      return;
    }

    let mainView = this.mainView;
    this.syncMediaPlayerWaiting = true;
    this.syncMediaViewAnnotationLayer = this.mainView.createAnnotationLayer('syncmedia-tts-highlights');
    this.syncMediaViewAnnotationLayer.setLayerOptions({
      layerStyle: {
        'mix-blend-mode': 'multiply',
        opacity: '0.4',
      }
    });
    this.syncMediaViewAnnotationLayer.setDefaultAnnotationOptions({
      rangeStyle: {
        'background-color': 'yellow'
      }
    });

    this.fetchSyncMediaTimeline(this.syncMediaViewAnnotationLayer).then(async timeline => {
      if (timeline) {
        let readingPosition = mainView.getReadingPosition();
        let timelinePosition: ISyncMediaTimelinePosition | null = null;
        if (readingPosition) {
          try {
            timelinePosition = await timeline.fetchTimelinePosition(readingPosition.getLocator());
          } catch (e) {
            console.error(e);
          }
        }

        console.log(timelinePosition);
        //let syncMediaPlayer = mainView.getReadingSystemEngine().createSyncMediaPlayer(timeline);
        //mainView.setSyncMediaPlayer(syncMediaPlayer);

        /*if (timelinePosition) {
          syncMediaPlayer.seekToTimelinePosition(timelinePosition);
        }
        this.syncMediaPlayerWaiting = !syncMediaPlayer.isReady();*/

      }
    });
  }

  /**
   * Toggles the playback between playing and paused
   * */
  synchronisedMediaPlayerPlayPause() {
    //console.log('synchronisedMediaPlayerPlayPause()')
    if (this.mainView) {
      let mediaPlayer = this.mainView.getSyncMediaPlayer();
      if (mediaPlayer) {
        if (!this.syncMediaPlayerPlaying) {
          mediaPlayer.play();
        } else {
          mediaPlayer.pause();
        }
      }
    }
  }

  /**
   * seekToNextSegment jumps to a new segment in the timeline. Depending on the playing/paused state of the media player,
   * the new segment may start playing directly.
   */
  synchronisedMediaPlayerSeekNext() {
    if (this.mainView) {
      let mediaPlayer = this.mainView.getSyncMediaPlayer();
      if (mediaPlayer) {
        let snapToVisiblePagesBoundary = true;
        mediaPlayer.seekToNextSegment(snapToVisiblePagesBoundary);
      }
    }
  }

  synchronisedMediaPlayerEscape() {
    if (this.mainView) {
      let mediaPlayer = this.mainView.getSyncMediaPlayer();
      if (mediaPlayer && this.timelineEscapePosition) {
        mediaPlayer.seekToTimelinePosition(this.timelineEscapePosition);
      }
    }
  }

  /**
   * seekToPreviousSegment jumps to the previous segment in the timeline. Depending on the playing/paused state of the media player,
   * the new item may start playing directly.
   * This method is a bit more complex than the 'synchronisedMediaPlayerSeekNext' method. The reason for this is
   * that if the media player is 'playing' and the user wants to skip to a previous segment it needs to give the user
   * some margin to click multiple times before it resumes playback.
   * */
  synchronisedMediaPlayerPlayPrevious() {
    if (this.mainView) {
      let mediaPlayer = this.mainView.getSyncMediaPlayer();
      if (!mediaPlayer) {
        return;
      }

      let shouldUseTemporaryPause = false;
      if (this.syncMediaTempPauseTimeoutId !== null) {
        window.clearTimeout(this.syncMediaTempPauseTimeoutId);
        this.syncMediaTempPauseTimeoutId = null;
        shouldUseTemporaryPause = true;
      } else if (!mediaPlayer.isPaused()) {
        shouldUseTemporaryPause = true;
      }
      if (shouldUseTemporaryPause) {
        mediaPlayer.pause();
        let snapToVisiblePagesBoundary = true;
        mediaPlayer.seekToPreviousSegment(snapToVisiblePagesBoundary);
        this.syncMediaTempPauseTimeoutId = window.setTimeout(() => {
          if (this.mainView) {
            let mediaPlayer = this.mainView.getSyncMediaPlayer();
            if (mediaPlayer) {
              mediaPlayer.play();
            }
          }
          this.syncMediaTempPauseTimeoutId = null;
        }, 1000);

      } else {
        let snapToVisiblePagesBoundary = true;
        mediaPlayer.seekToPreviousSegment(snapToVisiblePagesBoundary);
      }
    }
  }

  syncMediaPlayerVolumeChange(volume: number | Event) {
    if (this.mainView) {
      let mediaPlayer = this.mainView.getSyncMediaPlayer();
      if (mediaPlayer) {
        if (volume instanceof Event) {
          volume = parseInt((volume.srcElement as HTMLInputElement)!.value.toString());
        }
        this.syncMediaPlayerPlaybackRate = volume;
        mediaPlayer.setVolume(volume / 100);
      }
    }
  }

  syncMediaVolumeDialogOnKeyDownEsc(e: KeyboardEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.syncMediaVolumeDialogVisible = false;
  }

  syncMediaRateDialogOnKeyDownEsc(e: KeyboardEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.syncMediaRateDialogVisible = false;
  }

  syncMediaPlayerToggleMute() {
    if (this.mainView) {
      this.syncMediaPlayerMuted = !this.syncMediaPlayerMuted;
      let mediaPlayer = this.mainView.getSyncMediaPlayer();
      if (mediaPlayer) {
        mediaPlayer.setMuted(this.syncMediaPlayerMuted);
      }
    }
  }

  syncMediaPlayerPlaybackRateIncrease() {
    if (this.mainView) {
      let mediaPlayer = this.mainView.getSyncMediaPlayer();
      if (mediaPlayer) {
        this.syncMediaPlayerPlaybackRate = Math.min(Math.max(50, (this.syncMediaPlayerPlaybackRate + 10)), 250);
        mediaPlayer.setPlaybackRate(this.syncMediaPlayerPlaybackRate / 100);
      }
    }
  }

  syncMediaPlayerPlaybackRateDecrease() {
    if (this.mainView) {
      let mediaPlayer = this.mainView.getSyncMediaPlayer();
      if (mediaPlayer) {
        this.syncMediaPlayerPlaybackRate = Math.min(Math.max(50, (this.syncMediaPlayerPlaybackRate - 10)), 250);
        mediaPlayer.setPlaybackRate(this.syncMediaPlayerPlaybackRate / 100);
      }
    }

  }

  syncMediaPlayerPlaybackRateChange(rate: number | Event) {
    if (this.mainView) {
      let mediaPlayer = this.mainView.getSyncMediaPlayer();
      if (mediaPlayer) {
        if (rate instanceof Event) {
          rate = parseInt((rate.srcElement as HTMLInputElement)!.value.toString());
        }
        this.syncMediaPlayerPlaybackRate = rate;
        mediaPlayer.setPlaybackRate(rate / 100);
      }
    }
  }

  syncMediaHighlightColorChange(color: string | Event) {
    if (this.mainView) {
      //console.log(color);
      if (color instanceof Event) {
        color = (color.srcElement as HTMLInputElement)!.value.toString() || 'transparent';
      }
      //console.log(color);
      this.syncMediaHighlightColor = color;

      if (this.syncMediaViewAnnotationLayer) {
        this.syncMediaViewAnnotationLayer.setDefaultAnnotationOptions({
          rangeStyle: {'background-color': this.syncMediaHighlightColor}
        });
      }
      let readingSystem = this.mainView.getReadingSystemEngine();
      let publication = readingSystem.getReaderPublications()[0] as IEpubReaderPublication;
      publication.setOptions({
        customPublicationCss: {
          injectionPointEnd: [
            ".-epub-media-overlay-active { background-color: " + this.syncMediaHighlightColor + "}"
          ]
        }
      });

      readingSystem.getReaderViews().forEach(view => view.refresh(true));

    }
  }

  syncMediaVolumeUiToggle() {
    if (this.syncMediaPlayerMuted) {
      this.syncMediaPlayerToggleMute();
    }
  }

  onAppNavDrawerOpened() {
    this.$nextTick(() => {
      this.navDrawerOpen = true;
      this.ariaNotify('Navigation drawer opened. Press the Escape key to close.');
    });
  }

  onAppNavDrawerClosed() {
    this.ariaNotify('Navigation drawer closed.');
    (this.$refs.readerViewElement as HTMLElement).focus();
    this.navDrawerOpen = false;
  }

  onAppSettingsDrawerOpened() {
    this.$nextTick(() => {
      this.settingsDrawerOpen = true;
      this.ariaNotify('Settings drawer opened. Press the Escape key to close.');
    });
  }

  onAppSettingsDrawerClosed() {
    this.settingsDrawerOpen = false;
    this.ariaNotify('Settings drawer closed.');
    (this.$refs.readerViewElement as HTMLElement).focus();
  }

  getLandmarksForVisibleDocuments(): IContentDocumentData[] {
    let landmarks: IContentDocumentData[] = [];
    let activeReaderDocuments = this.mainView!.getVisiblePages().map(p => p.getReaderDocument());
    activeReaderDocuments.forEach((doc) => {
      let landmark = this.contentDocumentsLandmarks!.get(doc);
      if (landmark && landmarks.findIndex(item => item === landmark) < 0) {
        landmarks.push(landmark);
      }
    });

    return landmarks;
  }

  getLandmarksForLoadedDocuments(): IContentDocumentData[] {
    let landmarks: IContentDocumentData[] = [];
    let activeReaderDocuments = this.mainView!.getVisibleReaderDocuments();
    activeReaderDocuments.forEach((doc) => {
      let landmark = this.contentDocumentsLandmarks!.get(doc);
      if (landmark && landmarks.findIndex(item => item == landmark) < 0) {
        landmarks.push(landmark);
      }
    });
    return landmarks;
  }

  async populateContentDocumentsLandmarks() {
    let loadedReaderDocuments = this.mainView!.getVisibleReaderDocuments();

    for (const doc of loadedReaderDocuments) {

      if (this.contentDocumentsLandmarks!.has(doc)) {
        continue;
      }

      let documentLocator = doc.getLocator();
      let selectors = documentLocator.getSelectors();
      let selector = (selectors[0] instanceof Object ? (selectors[0] as IFragmentSelector).getValue() : selectors[0]) as string;

      let docLandmarks: IContentDocumentData = {
        documentId: selector,
        headings: null,
        tables: null,
        figures: null,
        audio: null,
        video: null,
        lists: null
      };

      let contentBlockTree = await doc.fetchContentBlockTree();
      let walker = new TreeNodeWalker(contentBlockTree.getContentBlocks());

      while (walker.hasNext()) {
        let block = walker.next() as IContentBlock;
        let blockType = block.getBlockType();
        // let blockAnnotationTarget = block.getAnnotationTarget();
        // let selector = (blockAnnotationTarget.selector instanceof Object ? (blockAnnotationTarget.selector as IAnnotationSelector).value : blockAnnotationTarget.selector) as string;

        switch (blockType) {
          case TextContentBlockType.HEADING1:
          case TextContentBlockType.HEADING2:
          case TextContentBlockType.HEADING3:
          case TextContentBlockType.HEADING4:
          case TextContentBlockType.HEADING5:
            docLandmarks.headings = docLandmarks.headings ? docLandmarks.headings : [];
            docLandmarks.headings.push(block);
            break;
          case MediaContentBlockType.AUDIO:
            docLandmarks.audio = docLandmarks.audio ? docLandmarks.audio : [];
            docLandmarks.audio.push(block);
            break;
          case MediaContentBlockType.VIDEO:
            docLandmarks.video = docLandmarks.video ? docLandmarks.video : [];
            docLandmarks.video.push(block);
            break;
          case MediaContentBlockType.IMAGE:
          case ContainerContentBlockType.FIGURE:
            docLandmarks.figures = docLandmarks.figures ? docLandmarks.figures : [];
            docLandmarks.figures.push(block);
            break;
          case ContainerContentBlockType.TABLE:
            docLandmarks.tables = docLandmarks.tables ? docLandmarks.tables : [];
            docLandmarks.tables.push(block);
            break;
          case ContainerContentBlockType.DESCRIPTION_LIST:
          case ContainerContentBlockType.UNORDERED_LIST:
          case ContainerContentBlockType.ORDERED_LIST:
            docLandmarks.lists = docLandmarks.lists ? docLandmarks.lists : [];
            docLandmarks.lists.push(block);
            break;

          default:

        }
      }

      this.contentDocumentsLandmarks!.set(doc, docLandmarks);

    }
  }

  getDescriptionAttributeFromContentBlock(block: IContentBlock, preferredAttributeName: string | undefined = undefined): string | undefined {
    let blockAttributes = block.getAttributes();
    let descAttribute: string | undefined = undefined;

    if (preferredAttributeName) {
      let attr = blockAttributes.find((attr: IAttributeData) => {
        return attr.localName == preferredAttributeName;
      });
      if (attr) {
        return attr.value;
      }
    }

    blockAttributes.forEach((attr: IAttributeData) => {
      switch (attr.localName) {
        case 'title':
          descAttribute = attr.value;
          break;
        case 'alt':
          descAttribute = attr.value;
          break;
        case 'aria-label':
          descAttribute = attr.value;
          break;
      }
    });

    return descAttribute;
  }

  getSelectorStringFromContentBlock(block: IContentBlock): string {
    let blockAnnotationTarget = block.getContentLocation().getLocator();
    let selectors = blockAnnotationTarget.getSelectors();
    let selector = (selectors instanceof Object ? (selectors[0] as IFragmentSelector).getValue() : selectors[0]) as string;
    return selector || '';
  }

  onContentDocumentLandmarksDialogClose() {
    this.appPublicationLandmarksDialogVisible = false;
    GlobalEventBus.$emit(GlobalEventName.APP_DIALOG_LANDMARKS_CLOSED);
  }

  onAppNarrationModeChange(value: any) {
    this.appColibrioSpeechPlaybackOnly = value;
    this.$nextTick(() => {
      this.mainView!.refresh();
    });
  }

  appImageZoomDialogShow(mediaResource: IEngineEventMediaResource) {
    mediaResource.createUrl().then(url => {
      if (this.appZoomedImageUrl) {
        // We could get here if we got two fast clicks on the same image before createUrl() returns.
        // Make sure we only keep one.
        mediaResource.revokeUrl(url);
        return;
      }
      this.appZoomedImageUrl = url;
      this.appImageZoomDialogVisible = true;
      this.revokeZoomedImageUrl = () => {
        mediaResource.revokeUrl(url);
      }
    });

    this.ariaNotify('Image fullscreen viewer opened. Press the Escape key to close.');
  }

  ariaNotify(msg: string, timeout: number = 2000) {
    this.appAriaNotificationMessage = msg;
    window.setTimeout(() => {
      this.appAriaNotificationMessage = '';
    }, timeout);
  }

  viewIsInModalState() {
    return (
        this.appPublicationLandmarksDialogVisible ||
        this.appImageZoomDialogVisible ||
        this.syncMediaRateDialogVisible ||
        this.syncMediaVolumeDialogVisible ||
        this.settingsDrawerOpen ||
        this.navDrawerOpen
    );
  }
}

interface IContentDocumentData {
  documentId?: string | null,
  headings?: IContentBlock[] | null,
  figures?: IContentBlock[] | null,
  tables?: IContentBlock[] | null,
  video?: IContentBlock[] | null,
  audio?: IContentBlock[] | null,
  lists?: IContentBlock[] | null
  anchors?: IContentBlockMarkData[] | null
}

function isEpubReaderPublication(readerPublication: IReaderPublication): readerPublication is IEpubReaderPublication {
  return readerPublication.getSourcePublication().getMediaType() === MediaType.APPLICATION_EPUB_ZIP;
}

