/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {
    IReaderPublication,
    IReadingSystemEngine
} from '../lib/colibrio-publishing-framework/colibrio-readingsystem-base';
import {
    IRandomAccessDataSource,
    IResourceProvider,
    MediaType
} from '../lib/colibrio-publishing-framework/colibrio-core-io-base';
import {MediaTypeDetector} from '../lib/colibrio-publishing-framework/colibrio-core-io-mediatypedetector';
import {IPublication} from '../lib/colibrio-publishing-framework/colibrio-core-publication-base';
import {HttpDataSource} from '../utils/io/http/HttpDataSource';
import {HttpHeaderReader} from '../utils/io/http/HttpHeaderReader';
import {Base64} from "../lib/colibrio-publishing-framework/colibrio-core-base";
import {
    EpubRemoteResourcePolicyType,
    IEpubReaderPublicationOptions
} from "../lib/colibrio-publishing-framework/colibrio-readingsystem-formatadapter-epub";
import {
    IZipResourceProvider,
    ZipResourceProvider
} from "../lib/colibrio-publishing-framework/colibrio-core-io-resourceprovider-zip";
import {License} from "./License";
import {IPdfReaderPublicationOptions} from "../lib/colibrio-publishing-framework/colibrio-readingsystem-formatadapter-pdf";

/**
 * Loads publications into our ReadingSystemEngine
 */
export class PublicationLoader {

    private pdfFormatAdapterPromise: Promise<void> | null = null;
    private epubFormatAdapterPromise: Promise<void> | null = null;
    private publications: IPublication[] = [];
    private resourceProviders: IResourceProvider[] = [];
    private destroyed = false;

    constructor(private readingSystemEngine: IReadingSystemEngine) {
    }

    destroy(): void {
        if (!this.destroyed) {
            this.destroyed = true;
            this.publications.forEach(publication => publication.destroy());
            //this.resourceProviders.forEach(resourceProvider => resourceProvider.destroy());
        }
    }

    loadPublicationFromBlob(blob: Blob): Promise<IReaderPublication> {
        return MediaTypeDetector.detectFromBlob(blob).then(mediaType => {
            return this.loadFromDataSource(blob, mediaType || blob.type)
        });
    }

    loadPublicationFromUrl(url: string, contentLength?: number): Promise<IReaderPublication> {

        if (typeof contentLength === "number") {
            let dataSource = new HttpDataSource(url, contentLength);
            return this.loadFromDataSource(dataSource, MediaType.APPLICATION_EPUB_ZIP);
        }

        return HttpHeaderReader.fromUrl(url).then(resourceInfo => {

            if (!this.isSupportedMediaType(resourceInfo.mediaType)) {
                throw new Error('Media type not supported: ' + resourceInfo.mediaType);
            }

            let dataSource = new HttpDataSource(url, resourceInfo.size);
            return this.loadFromDataSource(dataSource, resourceInfo.mediaType, url);
        });

    }

    loadPublicationPdfFromUrl(url: string, contentLength?: number): Promise<IReaderPublication> {

        if (typeof contentLength === "number") {
            let dataSource = new HttpDataSource(url, contentLength);
            return this.loadFromDataSource(dataSource, MediaType.APPLICATION_PDF);
        }

        return HttpHeaderReader.fromUrl(url).then(resourceInfo => {

            if (!this.isSupportedMediaType(resourceInfo.mediaType)) {
                throw new Error('Media type not supported: ' + resourceInfo.mediaType);
            }

            let dataSource = new HttpDataSource(url, resourceInfo.size);
            return this.loadFromDataSource(dataSource, resourceInfo.mediaType, url);
        });

    }

    loadPublicationFromBase64(base64: string): Promise<IReaderPublication> {
        let arr = Base64.decode(base64);
        return this.loadFromDataSource(arr, MediaTypeDetector.detectFromUint8Array(arr) || '');
    }

    private async loadFromDataSource(dataSource: Blob | IRandomAccessDataSource | Uint8Array, mediaType: string, _url?: string): Promise<IReaderPublication> {
        if (this.destroyed) {
            return Promise.reject('Publication loader destroyed. Aborting.');
        }

        switch (mediaType) {
            case MediaType.APPLICATION_PDF:
                return this.loadPdf(dataSource);

            case MediaType.APPLICATION_ZIP:
            case MediaType.APPLICATION_EPUB_ZIP:
                return this.loadEpub(dataSource);

        }
        throw new Error('Unsupported publication format: ' + mediaType);
    }

    /**
     * We use dynamic imports to load "colibrio.core.epub" and "colibrio.readingsystem.formatadapter.epub".
     * Webpack will automatically split our JS bundle and only load those modules if we load an EPUB.
     *
     * @param dataSource - The EPUB data source
     */
    private async loadEpub(dataSource: Blob | IRandomAccessDataSource | Uint8Array | IZipResourceProvider): Promise<IReaderPublication> {

        // First we need to download colibrio.core.publication.epub
        let publicationPromise: Promise<IPublication> = this.loadCoreEpubModule().then(coreEpubModule => {
            if (this.destroyed) {
                throw 'Publication loader destroyed. Aborting.';
            }
            let promise: Promise<IZipResourceProvider>;

            // Now lets create a OcfResourceProvider instance from our data source.
            if (dataSource instanceof Blob) {
                promise = ZipResourceProvider.createFromBlob(dataSource);
            } else if (dataSource instanceof Uint8Array) {
                promise = ZipResourceProvider.createFromArrayBuffer(dataSource.buffer, true);
            } else if (typeof (dataSource as IRandomAccessDataSource).fetchChunk === "function") {
                promise = ZipResourceProvider.createFromRandomAccessDataSource(dataSource as IRandomAccessDataSource, {
                    transferBuffers: true, // If we want to save the buffer to indexedDB as well, we need to set it to FALSE
                    chunkCacheSize: 0
                });
            } else {
                promise = Promise.resolve(dataSource as IZipResourceProvider);
            }

            return promise.then(zipResourceProvider => {
                if (this.destroyed) {
                    zipResourceProvider.destroy();
                    throw 'Publication loader destroyed. Aborting.';
                }
                let signature = zipResourceProvider.getCentralDirectorySha1Signature();
                return coreEpubModule.EpubOcfResourceProvider.createFromBackingResourceProvider(zipResourceProvider, signature);
            })
        }).then(ocfProvider => {
            if (this.destroyed) {
                ocfProvider.destroy();
                throw 'Publication loader destroyed. Aborting.';
            }

            this.resourceProviders.push(ocfProvider);
            // Lets get the default publication from the EPUB OCF.
            let defaultPublication: IPublication | null = ocfProvider.getDefaultPublication();
            if (!defaultPublication) {
                throw new Error('No publication found in the EPUB file');
            }
            return defaultPublication;
        });

        // Wait until the EPUB publication and the EPUB Format adapter have loaded.
        let [epubPublication] = await Promise.all([
            publicationPromise,
            this.loadEpubFormatAdapter()
        ]);
        if (this.destroyed) {
            return Promise.reject('Publication loader destroyed. Aborting.');
        }
        this.publications.push(epubPublication);

        let epubOptions: Partial<IEpubReaderPublicationOptions> = {
            mediaOverlayOptions: {
                fallbackActiveElementClass: '-epub-media-overlay-active'
            },
            remoteResourcesScriptedDocumentsOptions: {
                policyType: EpubRemoteResourcePolicyType.ALLOW_ALL,
            }
        };

        // licenseOptions is only required when using the Colibrio Reader Cloud License SDK
        return this.readingSystemEngine.loadPublication(epubPublication, epubOptions, License.getLicenseOptions(epubPublication));
    }

    // private createZipResourceProvider(dataSource: Blob | IRandomAccessDataSource | Uint8Array): Promise<IZipResourceProvider> {
    //     let zipResourceProvider: Promise<IZipResourceProvider>;
    //     if (dataSource instanceof Blob) {
    //         zipResourceProvider = ZipResourceProvider.createFromBlob(dataSource);
    //     } else if (dataSource instanceof Uint8Array) {
    //         zipResourceProvider = ZipResourceProvider.createFromArrayBuffer(dataSource.buffer, true);
    //     } else {
    //         zipResourceProvider = ZipResourceProvider.createFromRandomAccessDataSource(dataSource);
    //     }
    //
    //     return zipResourceProvider;
    // }

    /**
     * We use dynamic imports to load "colibrio.core.publication.pdf" and "colibrio.readingsystem.formatadapter.pdf".
     * Webpack will automatically split our JS bundle and only load those modules if we load an EPUB.
     *
     * @param dataSource - The EPUB data source
     */
    private async loadPdf(dataSource: Blob | IRandomAccessDataSource | Uint8Array): Promise<IReaderPublication> {

        // Download the colibrio.core.pdf module and then create a PDFPublication instance.
        let publicationPromise: Promise<IPublication> = this.loadCorePdfModule().then(corePdfModule => {
            if (this.destroyed) {
                return Promise.reject('Publication loader destroyed. Aborting.');
            }
            if (dataSource instanceof Blob) {
                return corePdfModule.PdfPublication.createFromBlob(dataSource);
            } else if (dataSource instanceof Uint8Array) {
                return corePdfModule.PdfPublication.createFromArrayBuffer(dataSource.buffer);
            } else {
                return corePdfModule.PdfPublication.createFromRandomAccessDataSource(dataSource);
            }
        });

        // Wait until the PDF publication and the PDF Format adapter have loaded.
        let [pdfPublication] = await Promise.all([
            publicationPromise,
            this.loadPdfFormatAdapter(),
        ]);
        if (this.destroyed) {
            return Promise.reject('Publication loader destroyed. Aborting.');
        }
        this.publications.push(pdfPublication);

        let publicationOptions: Partial<IPdfReaderPublicationOptions> = {
            // maxCanvasPixels: 10e6,
            // highResScaleThreshold: 1.5
        };
        // licenseOptions is only required when using the Colibrio Reader Cloud License SDK
        return this.readingSystemEngine.loadPublication(pdfPublication, publicationOptions, License.getLicenseOptions(pdfPublication));
    }

    /**
     * Dynamically import "colibrio.core.publication.epub"
     */
    private loadCoreEpubModule() {
        return import('../lib/colibrio-publishing-framework/colibrio-core-publication-epub');
    }

    /**
     * Dynamically import colibrio.core.publication.pdf
     */
    private loadCorePdfModule() {
        return import('../lib/colibrio-publishing-framework/colibrio-core-publication-pdf');
    }

    private isSupportedMediaType(mediaType: any): mediaType is MediaType {
        switch (mediaType) {
            case MediaType.APPLICATION_EPUB_ZIP:
            case MediaType.APPLICATION_ZIP:
            case MediaType.APPLICATION_PDF:
            case MediaType.TEXT_HTML:
            case 'application/json':
                return true;
        }

        return false;
    }

    private loadPdfFormatAdapter(): Promise<void> {
        if (!this.pdfFormatAdapterPromise) {

            this.pdfFormatAdapterPromise = import('../lib/colibrio-publishing-framework/colibrio-readingsystem-formatadapter-pdf').then(pdfAdapterModule => {
                this.readingSystemEngine.addFormatAdapter(new pdfAdapterModule.PdfFormatAdapter());
            }).catch(err => {
                console.error(err);
                this.pdfFormatAdapterPromise = null;
                throw err;
            })
        }
        return this.pdfFormatAdapterPromise;
    }

    private loadEpubFormatAdapter(): Promise<void> {
        if (!this.epubFormatAdapterPromise) {

            this.epubFormatAdapterPromise = import('../lib/colibrio-publishing-framework/colibrio-readingsystem-formatadapter-epub').then(epubAdapterModule => {
                this.readingSystemEngine.addFormatAdapter(new epubAdapterModule.EpubFormatAdapter());
            }).catch(err => {
                console.error(err);
                this.epubFormatAdapterPromise = null;
                throw err;
            })
        }
        return this.epubFormatAdapterPromise;
    }

}
