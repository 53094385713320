import axios from "axios";


export const getCookie = () => {
    let cookie = document.cookie;
    let array_cookie = cookie.split(";");
    let seprate_cookie_array = array_cookie.map((cookie) => cookie.split("="));
    return seprate_cookie_array.reduce(
        (accumulator, [key, value]) => ({
            ...accumulator,
            [key.trim()]: decodeURIComponent(value),
        }),
        {}
    );
}

export const postRequest = (url: string, data: object) => {
    return new Promise((myResolve, myReject) => {

        let __mycookie = getCookie();
        // @ts-ignore
        let session_data = JSON.parse(atob(__mycookie.__ajxd));


        if (session_data.token === undefined) {
            return {};
        }

        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + session_data.token;


        axios.post(url, data)
            .then((data) => {
                return myResolve(data.data);
            })
            .catch((error) => {
                console.log(error)
                return myReject({
                    data: error.response.data,
                    status: error.response.status
                });
            })
    });
}
export const openPostRequest = (url: string, data: object) => {
    return new Promise((myResolve, myReject) => {

        axios.post(url, data)
            .then((data) => {
                return myResolve(data.data);
            })
            .catch((error) => {
                console.log(error)
                return myReject({
                    data: error.response.data,
                    status: error.response.status
                });
            })
    });
}

export const deleteRequest = (url: string, id: string | number) => {
    return new Promise((myResolve, myReject) => {

        let __mycookie = getCookie();
        // @ts-ignore
        let session_data = JSON.parse(atob(__mycookie.__ajxd));

        if (session_data.token === undefined) {
            return {};
        }

        //session_data.token = "146|qXLKzsx9E4Pe8MDDGNRjrbQpiuqxnngrqy0UIywJ";

        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + session_data.token;


        axios.post(url + '/' + id , {
            '_method': 'DELETE'
        })
            .then((data) => {
                return myResolve(data.data);
            })
            .catch((error) => {
                console.log(error.response.data)
                console.log(error.response.status)
                return myReject({
                    data: error.response.data,
                    status: error.response.status
                });
            })
    });
}
