/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import { ReadingSystemEngine } from '../lib/colibrio-publishing-framework/colibrio-readingsystem-engine';
import { ReadingSystemIndexEngine } from '../lib/colibrio-publishing-framework/colibrio-readingsystem-indexengine';
import {
    IReaderPublication
} from '../lib/colibrio-publishing-framework/colibrio-readingsystem-base';
import { PublicationLoader } from './PublicationLoader';

export class ReaderModel {

    public indexEngine: ReadingSystemIndexEngine = new ReadingSystemIndexEngine();
    private readingSystem: ReadingSystemEngine;
    private publicationLoader: PublicationLoader;
    public isEmbedded = (window.parent !== window);
    public darkModeEnabled: boolean = false;

    constructor() {
        this.readingSystem = ReaderModel.createReadingSystemEngine();
        this.publicationLoader = new PublicationLoader(this.readingSystem);
    }

    async loadPublicationFromBlob(blob: Blob): Promise<IReaderPublication> {
        let readerPublication = await this.publicationLoader.loadPublicationFromBlob(blob);
        this.indexEngine.mountReaderPublication(readerPublication);
        return readerPublication;
    }

    async loadPublicationFromUrl(publicationUrl: string, contentLength?: number): Promise<IReaderPublication> {
        let readerPublication = await this.publicationLoader.loadPublicationFromUrl(publicationUrl, contentLength);

        this.indexEngine.mountReaderPublication(readerPublication);
        return readerPublication;
    }

    async loadPdfPublicationFromUrl(publicationUrl: string, contentLength?: number): Promise<IReaderPublication> {
        let readerPublication = await this.publicationLoader.loadPublicationPdfFromUrl(publicationUrl, contentLength);

        this.indexEngine.mountReaderPublication(readerPublication);
        return readerPublication;
    }

    async loadPublicationFromBase64(base64: string): Promise<IReaderPublication> {
        let readerPublication = await this.publicationLoader.loadPublicationFromBase64(base64);
        this.indexEngine.mountReaderPublication(readerPublication);
        return readerPublication;
    }

    unloadAll(): void {
        this.readingSystem.getReaderPublications().forEach(readerPublication => this.indexEngine.dismountReaderPublication(readerPublication));
        this.readingSystem.destroy();
        this.publicationLoader.destroy();
        this.readingSystem = ReaderModel.createReadingSystemEngine();
        this.publicationLoader = new PublicationLoader(this.readingSystem);
    }

    getReadingSystem(): ReadingSystemEngine {
        return this.readingSystem;
    }

    getReaderWindowContext() {
        return window.parent === window ? window : window.parent;
    }

    private static createReadingSystemEngine(): ReadingSystemEngine {
        return new ReadingSystemEngine({
            licenseApiKey: process.env.LICENSE_API_KEY as string// __COLIBRIO_LICENSE_API_KEY__ is replaced when building with webpack using the contents of the LICENSE_API_KEY file.
        });
    }

}

export let readerModel = new ReaderModel();
