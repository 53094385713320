
import Vue from "vue";
import Component from "vue-class-component";
import {readerModel} from "../../model/ReaderModel";
import {GlobalEventBus} from "../../GlobalEventBus";
import {GlobalEventName} from "../../GlobalEventName";
import {IReaderPublication} from "../../lib/colibrio-publishing-framework/colibrio-readingsystem-base";

import {createFocusTrap} from 'focus-trap';
import '../../assets/css/rangeslider.scss';
import {IFragmentSelector} from "../../lib/colibrio-publishing-framework/colibrio-core-selector-base";
import ColibrioLoader from "../colibrio-loader/colibrio-loader.vue";
import ColibrioPublicationNav from "./publication-nav/colibrio-publication-nav.vue";
import ColibrioPublicationView from "./publication-view/colibrio-publication-view.vue";
import ColibrioPublicationSettings from "./publication-settings/colibrio-publication-settings.vue";
import ColibrioFileChooser from "./file-loader/colibrio-file-chooser.vue";
import {deleteRequest, getCookie, openPostRequest, postRequest} from "../../config/helper"
import {
  BOOKMARK_CREATE,
  BOOKMARK_DELETE,
  BOOKMARK_LIST,
  CHAPTER_META_SAVE,
  CHAPTERS_LIST, GET_CHAPTER_TIMELINE
} from "../../config/URLConstants";
import {ColibrioPublicationNavCollection} from "./publication-nav/ColibrioPublicationNavCollection";
import {Watch} from "vue-property-decorator";
import "../../config/amcharts5";
import "../../config/amcharts5/map.js";
import "../../config/amcharts5/themes/Animated.js";
import {am5geodata_worldLow} from "../../config/worldLow";

let initialPublicationUrl: string | null = null;
let readerType: string | null = null;

if (window.URLSearchParams && document.location.search) {
  let urlParams = new URLSearchParams(document.location.search);
  initialPublicationUrl = urlParams.get('publication_url') ? decodeURIComponent(urlParams.get('publication_url')!) : null;
  readerType = urlParams.get('reader_type') ? decodeURIComponent(urlParams.get('reader_type')!) : null;
}


@Component({
  name: 'colibrio-reader',
  components: {
    ColibrioLoader,
    ColibrioPublicationNav,
    ColibrioPublicationView,
    ColibrioPublicationSettings,
    ColibrioFileChooser
  }
})
export default class ColibrioApp extends Vue {
  internalNavDrawerVisible: boolean = false;
  internalSettingsDrawerVisible: boolean = false;
  state: 'empty' | 'presenting' | 'loading' | 'audiobook' = (initialPublicationUrl ? 'loading' : 'empty');
  title: string = 'Colibrio Reader';
  loadErrorDialog = false;
  errorText: string = '';
  leftSidebarTitle: string = '';
  initialLocation: string = window.location.hash;
  private currentLocationHash: string = '';
  useDarkTheme: boolean = readerModel.darkModeEnabled || true;
  navDrawerFocusTrap: any;
  settingsDrawerFocusTrap: any;
  appModalComponentActive: boolean = false;
  appColibrioSpeechPlaybackOnly: boolean = false;
  tabIndex: number = 0;
  fontSize: number = 0
  bookMarkList: Array<any> = [];
  chapterList = [];
  activeChapter: any = 0;
  reloadReader: boolean = true;
  isSample: boolean = false;
  bookmarkListLoader: boolean = false;
  chapterListLoader: boolean = false;
  bookmarkCreateLoader: boolean = false;
  bookmarkDeleteLoader: boolean = false;
  bookmarkDeleteIndex: number = -1;
  navCollectionsList: ColibrioPublicationNavCollection[] = [];
  activeChapterCheckbox: string = "";
  activeChapterName: string = ""
  bookPageColor: string | null = "Publication Default"
  timeLineReadMore: boolean = false;
  timeLineData: any = {};
  timeLineDataLoader: boolean = false;
  pointSeries: any;
  fileType: string = 'EPUB'


  get navDrawerVisible(): boolean {
    return this.internalNavDrawerVisible;
  }

  set navDrawerVisible(value: boolean) {
    if (this.internalNavDrawerVisible === value) {
      return;
    }

    this.internalNavDrawerVisible = value;
    if (value) {
      GlobalEventBus.$emit(GlobalEventName.APP_NAV_DRAWER_OPENED);
    } else {
      GlobalEventBus.$emit(GlobalEventName.APP_NAV_DRAWER_CLOSED);
    }
  }

  get settingsDrawerVisible(): boolean {
    return this.internalSettingsDrawerVisible;
  }

  set settingsDrawerVisible(value: boolean) {

    if (this.internalSettingsDrawerVisible === value) {
      return;
    }

    this.internalSettingsDrawerVisible = value;
    if (value) {
      GlobalEventBus.$emit(GlobalEventName.APP_SETTINGS_DRAWER_OPENED);

    } else {
      GlobalEventBus.$emit(GlobalEventName.APP_SETTINGS_DRAWER_CLOSED);
    }
  }

  @Watch('navCollectionsList')
  onNavCollectionListChange(chaptersList: any) {
    if (chaptersList.length > 0) {
      this.saveChaptersMeta()
    }
  }

  @Watch('activeChapterName')
  onChapterNameChange(chapterName: string) {
    if (chapterName !== "") {
      this.getTimelineData(chapterName)
    }
  }

  appDialogsSetAriaHiddenAttribute(value: string) {
    let appDialogElements = document.querySelectorAll('.v-dialog__content');
    if (appDialogElements) {
      for (let i = 0; appDialogElements.length > i; i++) {
        appDialogElements[i].setAttribute('aria-hidden', value);
      }
    }
  }

  getPublicationTitle(value: string) {
    this.leftSidebarTitle = value;
  }

  handleFontChange(value: any) {
    localStorage.setItem('font_size', value);
    let fontSize: any = localStorage.getItem("font_size");
    this.fontSize = parseInt(fontSize);
  }

  handlePageColorChange(value: any) {
    localStorage.setItem('book_color', value);
    this.bookPageColor = localStorage.getItem("book_color");
  }

  changeTabToZero(evt: any) {
    if (evt.target.mediaType !== "") {
      this.tabIndex = this.tabIndex !== 1 ? 0 : 1;
    }
  }

  unCheckAllCheckBox() {
    let inputs: any = document.querySelectorAll('.chapter-item');
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].checked = false;
    }
  }

  getBookmarkList() {
    const queryParams = this.$route.query;
    let __mycookie: any = getCookie();

    if (__mycookie.__ajxd !== undefined) {
      if (queryParams.book_id !== undefined) {
        this.bookmarkListLoader = true
        postRequest(BOOKMARK_LIST, {
          product_id: queryParams.book_id
        }).then((res: any) => {
          this.bookmarkListLoader = false;
          if (res.status !== undefined && res.status.toLowerCase() === "success") {
            this.bookMarkList = res.data;
          }
        })
            .catch((error) => {
              console.log(error);
              //this.loadErrorDialog = true;
              this.bookmarkListLoader = false;
              if (error.status !== undefined && error.status === 401) {
                this.errorText = "Unauthorized";
              }
            });
      }
    }


  }

  getChaptersList() {
    const queryParams = this.$route.query;
    if (queryParams.book_id !== undefined) {
      this.chapterListLoader = true;
      openPostRequest(CHAPTERS_LIST + '/' + queryParams.book_id, {})
          .then((res: any) => {
            this.chapterListLoader = false;
            if (res.status !== undefined && res.status.toLowerCase() === "success") {
              if (this.activeChapter === 0) {
                let chapter = res.data[0];
                const queryParams = this.$route.query;
                this.activeChapter = parseInt(chapter.id);
                const url = '/?type=sample&book_id=' + queryParams.book_id + '&chapter=' + chapter.id + '&publication_url=' + chapter.media_url;
                this.$router.push(url)

                setTimeout(() => {
                  window.location.reload();
                }, 200)
              }
              this.chapterList = res.data;
            }
          })
          .catch((error) => {
            console.log(error);
            //this.loadErrorDialog = true;
            this.chapterListLoader = false;

            if (error.status !== undefined && error.status === 401) {
              this.errorText = "Unauthorized";
            }
          });
    }

  }

  saveChaptersMeta() {
    const queryParams = this.$route.query;
    console.log(this.navCollectionsList.length);
    if (this.navCollectionsList.length > 0 && queryParams.book_id !== undefined) {
      const chaptersMeta = this.navCollectionsList.map((navData: any) => {
        return {
          id: navData.id,
          name: navData.textContent
        };
      });
      openPostRequest(CHAPTER_META_SAVE, {
        product_id: queryParams.book_id,
        meta_data: JSON.stringify(chaptersMeta)
      })
          .then((res: any) => {
            if (res.status !== undefined && res.status.toLowerCase() === "success") {
              console.log('Meta data',res)
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }

  }

  getTimelineData(chapter_name: string) {
    const queryParams = this.$route.query;
    if (queryParams.book_id !== undefined) {
      this.timeLineData = {};
      this.timeLineReadMore = false;
      this.pointSeries.data.setAll([]);
      this.timeLineDataLoader = true;
      openPostRequest(GET_CHAPTER_TIMELINE, {
        product_id: queryParams.book_id,
        chapter_name: chapter_name
      })
          .then((res: any) => {
            if (res.status !== undefined && res.status.toLowerCase() === "success") {
              console.log('Timeline data',res.data)
              this.timeLineData = res.data;

              // @ts-ignore
              const am5 = window.am5;

              if (this.timeLineData.map_meta_data !== undefined) {
                let mapDetails: any = JSON.parse(this.timeLineData.map_meta_data);
                this.pointSeries.data.setAll([{
                  geometry: { type: "Point", coordinates: [mapDetails.long, mapDetails.lat] },
                  title: mapDetails.title,
                  fill: am5.color(0xaaaaaa),
                  columnSettings: {
                    fill: am5.color(0x282828)
                  }
                }]);
              }


            }
            this.timeLineDataLoader = false;

          })
          .catch((error) => {
            console.log(error);
            this.timeLineDataLoader = false;
          });
    }
  }

  goToNavItem(childNavItem: any, index: number) {
    if (index < 9 || index > 61) {
      this.unCheckAllCheckBox();
      if (childNavItem.hasOwnProperty('locator')) {
        //@ts-ignore
        GlobalEventBus.$emit(GlobalEventName.APP_NAV_DRAWER_NAV_ITEM_CLICKED, childNavItem.locator);
      }
    }
  }
  goToBookMarkItem(childNavItem: any) {
    if (childNavItem.hasOwnProperty('mark_point')) {
      //@ts-ignore
      GlobalEventBus.$emit(GlobalEventName.APP_NAV_DRAWER_NAV_ITEM_CLICKED, childNavItem.mark_point);
    }
  }

  handleJumpToNext () {
    if (this.activeChapter !== 1 && this.chapterList[this.activeChapter + 1] !== undefined) {
      let chapter = this.chapterList[this.activeChapter + 1];
      this.handleViewChapter(chapter, this.activeChapter + 1)
    }
  }

  handleJumpToPrev () {
    if (this.activeChapter !== 0 && this.chapterList[this.activeChapter - 1] !== undefined) {
      let chapter = this.chapterList[this.activeChapter - 1];
      this.handleViewChapter(chapter, this.activeChapter - 1)
    }
  }

  handleActiveChapter (chapterName: string) {
    this.unCheckAllCheckBox();
    this.activeChapterName = chapterName;
    this.navCollectionsList.map((obj: any) => {
      obj.active = (obj.textContent === chapterName)
      return obj;
    })
  }

  handleViewChapter(chapter: any, index: number) {
    this.activeChapter = index

    const queryParams = this.$route.query;
    const url = '/?type=sample&book_id=' + queryParams.book_id + '&chapter=' + chapter.id + '&publication_url=' + chapter.media_url;
    this.$router.push(url)

    setTimeout(() => {
      window.location.reload();
    }, 200)
  }

  addBookmarkPoint() {
    let __mycookie: any = getCookie();

    if (__mycookie.__ajxd !== undefined) {
      const queryParams = this.$route.query;
      if (this.$route.hash !== "" && queryParams.book_id !== undefined) {
        let book_id = queryParams.book_id;
        if (queryParams.chapter !== undefined) {
          book_id = queryParams.chapter;
        }
        this.bookmarkCreateLoader = true;

        postRequest(BOOKMARK_CREATE, {
          product_id: book_id,
          mark_point: this.$route.hash,
          chapter_title: this.activeChapterName
        }).then((res: any) => {
          this.bookmarkCreateLoader = false;
          if (res.status !== undefined && res.status.toLowerCase() === "success") {
            if (res.data !== undefined) {
              this.bookMarkList.push(res.data);
            } else {
              this.getBookmarkList()
            }
          }
        })
            .catch((error) => {
              console.log(error);
              //this.loadErrorDialog = true;
              this.bookmarkCreateLoader = false;
              if (error.status !== undefined && error.status === 401) {
                this.errorText = "Unauthorized";
              }
            });
      } else {
        alert("Invalid book or bookmark string")
      }
    } else {
      alert("Login required to use this feature");
    }

  }

  jumpToBookmark(bookmark: any) {
    const queryParams = this.$route.query;
    let chapterUrl = "";
    if (queryParams.chapter !== undefined) {
      chapterUrl = '&chapter=' + queryParams.chapter;
      this.activeChapter = queryParams.chapter;
    }
    let publicationURL = queryParams.publication_url;
    if (bookmark.media_url !== undefined) {
      publicationURL = bookmark.media_url;
    }
    const url = '/?type=sample&book_id=' + bookmark.product.id + chapterUrl + '&publication_url=' + publicationURL + bookmark.mark_point;
    this.$router.push(url)

    setTimeout(() => {
      window.location.reload();
    }, 200)
  }

  deleteBookmark(index: number) {
    if (this.bookMarkList[index] !== undefined) {
      this.bookmarkDeleteIndex = index
      const tempBookMark: any = this.bookMarkList[index];
      this.bookmarkDeleteLoader = true;
      deleteRequest(BOOKMARK_DELETE, tempBookMark.id).then((res: any) => {
        this.bookmarkDeleteLoader = false;

        if (res.status !== undefined && res.status.toLowerCase() === "success") {
          this.bookMarkList.splice(index, 1);
        }
      })
          .catch((error) => {
            console.log(error);
            //this.loadErrorDialog = true;
            this.bookmarkDeleteLoader = false;
            if (error.status !== undefined && error.status === 401) {
              this.errorText = "Unauthorized";
            }
          });
    }
  }

  amChartsInit() {

    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    // @ts-ignore
    const am5map = window.am5map;
    // @ts-ignore
    const am5 = window.am5;
    // @ts-ignore
    const am5themes_Animated = window.am5themes_Animated

    var root = am5.Root.new("chartdiv");


    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root)
    ]);


    // Create the map chart
    // https://www.amcharts.com/docs/v5/charts/map-chart/
    var chart = root.container.children.push(am5map.MapChart.new(root, {
      panX: "none",
      panY: "none",
      wheelY: "none",
      wheelX: "none",
      pinchZoom: false,
      projection: am5map.geoMercator()
    }));

    // Create main polygon series for countries
    // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
    var polygonSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {
      geoJSON: am5geodata_worldLow,
      exclude: ["AQ"]
    }));

    polygonSeries.mapPolygons.template.setAll({
      tooltipText: "{name}",
      toggleKey: "active",
      fill: am5.color(0xec7447),
      interactive: true
    });

    polygonSeries.mapPolygons.template.states.create("hover", {
      fill: am5.color(0x661f00)
    });

    polygonSeries.mapPolygons.template.events.on("click", function(ev: any) {
      //ev.target.series.chart.zoomToMapObject(ev.target);

      // get object info
      console.log(ev.target.dataItem);
    });

    var pointSeries = chart.series.push(am5map.MapPointSeries.new(root, {}));
    this.pointSeries = pointSeries;
    // var colors = am5.ColorSet.new(root, {});
    // var colorset = am5.ColorSet.new(root, {});



    pointSeries.bullets.push(function () {
      var container = am5.Container.new(root, {});

      var circle = container.children.push(
          am5.Circle.new(root, {
            radius: 2,
            tooltipY: 0,
            fill: am5.color(0x661f00),
            strokeOpacity: 0,
            tooltipText: "{title}"
          })
      );


      circle.animate({
        key: "scale",
        from: 1,
        to: 10,
        duration: 1200,
        easing: am5.ease.out(am5.ease.cubic),
        loops: Infinity
      });

      circle.animate({
        key: "opacity",
        from: 1,
        to: 0,
        duration: 1200,
        easing: am5.ease.out(am5.ease.cubic),
        loops: Infinity
      });

      return am5.Bullet.new(root, {
        sprite: container
      });
    });


    /*var cities = [
      {
        title: "Brussels",
        latitude: 50.8371,
        longitude: 4.3676
      },
      {
        title: "Copenhagen",
        latitude: 55.6763,
        longitude: 12.5681
      },
      {
        title: "Mumbai",
        latitude: 19.0760,
        longitude: 72.8777
      }
    ];

    const addCity = (longitude: any, latitude: any, title: string) => {
      this.pointSeries.data.setAll([{
        geometry: { type: "Point", coordinates: [longitude, latitude] },
        title: title
      }]);
    }

    for (var i = 0; i < cities.length; i++) {
      var city = cities[i];
      addCity(city.longitude, city.latitude, city.title);
    }*/


    // Make stuff animate on load
    chart.appear(1000, 100);
  }



  mounted() {
    this.appDialogsSetAriaHiddenAttribute('true');

    this.amChartsInit();

    if (readerType !== undefined && readerType !== null) {
      //this.isSample = true;
    }

    if (localStorage.getItem("font_size") !== null) {
      let fontSize: any = localStorage.getItem("font_size");
      this.fontSize = parseInt(fontSize);
    } else {
      localStorage.setItem("font_size", "100");
      this.fontSize = 100;
    }

    if (localStorage.getItem("book_color") !== null) {
      this.bookPageColor = localStorage.getItem("book_color");
    }

    setTimeout(() => {
      this.saveChaptersMeta()
    }, 3000)



    let wrapper: any = document.querySelector('.bookReaderMenu');

    document.body.addEventListener('click', (e) => {
      if (!wrapper.contains(e.target)) {
        if (this.tabIndex !== 1) {
          this.tabIndex = 0;
        }
      }
    })

    const queryParams = this.$route.query;

    if (queryParams.chapter !== undefined) {
      this.activeChapter = parseInt(queryParams.chapter.toString());

    }

    this.getBookmarkList();
    //this.getChaptersList();

    this.currentLocationHash = window.location.hash;

    GlobalEventBus.$on(GlobalEventName.READER_PUBLICATION_LOAD_ERROR, (msg: any) => {
      this.loadErrorDialog = true;
      this.errorText = msg || '';
    });

    GlobalEventBus.$on(GlobalEventName.APP_NAV_DRAWER_NAV_ITEM_CLICKED, () => {
      this.navDrawerVisible = false;
    });

    GlobalEventBus.$on(GlobalEventName.APP_SETTINGS_THEME_CHANGED, (_args: any) => {
      this.useDarkTheme = readerModel.darkModeEnabled;//args.useDarkTheme;
    });


    GlobalEventBus.$on(GlobalEventName.APP_NAV_DRAWER_OPEN_INTENT, this.navDrawerOpen);
    GlobalEventBus.$on(GlobalEventName.APP_NAV_DRAWER_CLOSE_INTENT, this.navDrawerClose);
    GlobalEventBus.$on(GlobalEventName.APP_SETTINGS_DRAWER_OPEN_INTENT, this.settingsDrawerOpen);
    GlobalEventBus.$on(GlobalEventName.APP_SETTINGS_DRAWER_CLOSE_INTENT, this.settingsDrawerClose);

    GlobalEventBus.$on(GlobalEventName.APP_NAV_DRAWER_OPENED, this.navDrawerOpen);
    GlobalEventBus.$on(GlobalEventName.APP_NAV_DRAWER_CLOSED, this.navDrawerClose);
    GlobalEventBus.$on(GlobalEventName.APP_SETTINGS_DRAWER_OPENED, this.settingsDrawerOpen);
    GlobalEventBus.$on(GlobalEventName.APP_SETTINGS_DRAWER_CLOSED, this.settingsDrawerClose);

    GlobalEventBus.$on(GlobalEventName.APP_SETTINGS_TOGGLE_FULLSCREEN_MODE, this.toggleFullScreen);

    GlobalEventBus.$on(GlobalEventName.READER_PUBLICATION_CLOSE, this.onCloseReaderPublication);

    GlobalEventBus.$on(GlobalEventName.APP_SETTINGS_NARRATION_MODE_CHANGE_INTENT, this.onAppNarrationModeChange);

    window.addEventListener('load', () => {
      readerModel.getReaderWindowContext().postMessage({type: 'READING_SYSTEM_LOADED', value: ''}, '*');
    });

    window.addEventListener('message', (message: MessageEvent) => {
      switch (message.data.type) {
        case 'LOAD_PUBLICATION_URL':
          this.onUrlSelected(message.data.value);
          break;
        case 'LOAD_PUBLICATION_BLOB':
          this.onFileSelected(message.data.value);
          break;
        default:
      }
    });

    if (initialPublicationUrl && this.state === 'loading') {
      if (initialPublicationUrl.includes('.pdf')) {
        this.fileType = "PDF";
        this.onPdfUrlSelected(initialPublicationUrl);
      } else {
        this.fileType = "EPUB";
        this.onUrlSelected(initialPublicationUrl);
      }

      readerModel.getReadingSystem().addEngineEventListener<"readingPositionChanged">("readingPositionChanged", event => {
        let readingPosition = event.readerView.getReadingPosition();
        if (readingPosition) {

          let locator = readingPosition.getLocator();
          let selector = locator.getSelectors()[0];
          if (typeof selector === 'object') {
            if (selector.getType() === 'FragmentSelector') {
              window.location.hash = '#' + (selector as IFragmentSelector).getValue();
              this.currentLocationHash = window.location.hash;
            }
          }
        }
      });

      window.addEventListener("hashchange", () => {
        if (this.currentLocationHash !== window.location.hash) {
          GlobalEventBus.$emit(GlobalEventName.READER_VIEW_GOTO_FRAGMENT_SELECTOR, window.location.hash.slice(1));
          this.currentLocationHash = window.location.hash;
        }
      });
    }

  }

  changeNavCollection(value: any) {
    console.log(value[0]);
    if (value[0] !== undefined) {
      this.navCollectionsList = value[0].children || [];
    }
  }

  onCloseReaderPublication() {
    if (readerModel.isEmbedded) {
      readerModel.getReaderWindowContext().postMessage({type: 'READING_SESSION_CLOSE', value: ''}, '*');
    } else {
      this.state = "empty";
      readerModel.unloadAll();
      document.location.href = document.location.origin + document.location.pathname;
    }
  }

  onAppNarrationModeChange(value: any) {
    this.appColibrioSpeechPlaybackOnly = value;
  }

  onFileSelected(file: Blob): void {
    this.state = "loading";
    readerModel.loadPublicationFromBlob(file).then(readerPublication => this.onPublicationLoaded(readerPublication)).catch(err => {
      console.log(err);
      this.state = "empty";
      GlobalEventBus.$emit(GlobalEventName.READER_PUBLICATION_LOAD_ERROR);
    });
  }

  onPdfUrlSelected(url: string, contentLength?: number): void {
    this.state = "loading";
    readerModel.loadPdfPublicationFromUrl(url, contentLength).then(readerPublication => this.onPublicationLoaded(readerPublication)).catch(err => {
      console.log(err);
      this.state = "empty";
      GlobalEventBus.$emit(GlobalEventName.READER_PUBLICATION_LOAD_ERROR, 'Please ensure the URL is valid and Range Request is supported by the http server.');
    });

  }

  onUrlSelected(url: string, contentLength?: number): void {
    this.state = "loading";
    readerModel.loadPublicationFromUrl(url, contentLength).then(readerPublication => this.onPublicationLoaded(readerPublication)).catch(err => {
      console.log(err);
      this.state = "empty";
      GlobalEventBus.$emit(GlobalEventName.READER_PUBLICATION_LOAD_ERROR, 'Please ensure the URL is valid and Range Request is supported by the http server.');
    });

  }

  onToggleSettingsDrawer(): void {
    this.tabIndex = this.tabIndex !== 1 ? 0 : 1;
    this.settingsDrawerVisible = !this.settingsDrawerVisible && (this.state === "presenting" || this.state === 'audiobook');
    if (this.settingsDrawerVisible) {
      this.settingsDrawerOpen();
    } else {
      this.settingsDrawerClose();
    }
  }

  onToggleNavDrawer(): void {
    this.navDrawerVisible = !this.navDrawerVisible && (this.state === "presenting" || this.state === 'audiobook');
    if (this.navDrawerVisible) {
      this.navDrawerOpen();
    } else {
      this.navDrawerClose();
    }

  }

  private onPublicationLoaded(readerPublication: IReaderPublication) {
    this.state = 'presenting';
    let titles = readerPublication.getSourcePublication().getMetadata().getTitles();
    if (titles[0]) {
      this.title = titles[0].content.value;
    }

    GlobalEventBus.$emit(GlobalEventName.READER_PUBLICATION_LOADED, readerPublication);
    readerModel.getReaderWindowContext().postMessage({type: 'READER_PUBLICATION_LOADED', value: ''}, '*');
  }

  navDrawerClose() {
    console.info('navDrawerClose')
    this.navDrawerVisible = false;
    if (this.$refs.colibrioPublicationNavDrawer) {
      let drawer = ((this.$refs.colibrioPublicationNavDrawer as Vue).$el as HTMLElement);
      if (drawer) {
        drawer.style.visibility = 'hidden';
        this.appModalComponentActive = false;
        this.navDrawerFocusTrap.deactivate();
      }
    }
  }

  navDrawerOpen() {
    this.navDrawerVisible = true;
    if (this.$refs.colibrioPublicationNavDrawer) {
      let drawer = ((this.$refs.colibrioPublicationNavDrawer as Vue).$el as HTMLElement);
      if (drawer) {
        drawer.style.visibility = 'visible';
        this.appModalComponentActive = true;
        this.navDrawerFocusTrap = this.navDrawerFocusTrap || createFocusTrap(drawer, {
          clickOutsideDeactivates: true,
          escapeDeactivates: true,
          returnFocusOnDeactivate: false
        });
        window.setTimeout(() => {
          drawer.focus();
          this.navDrawerFocusTrap.activate();
        }, 500);
      }
    }
  }

  settingsDrawerClose() {
    this.settingsDrawerVisible = false;
    if (this.$refs.colibrioPublicationSettingsDrawer) {
      let drawer = ((this.$refs.colibrioPublicationSettingsDrawer as Vue).$el as HTMLElement);
      if (drawer) {
        drawer.style.visibility = 'hidden';
        this.appModalComponentActive = false;
        this.settingsDrawerFocusTrap.deactivate();
      }
    }
  }

  settingsDrawerOpen() {
    this.settingsDrawerVisible = true;
    if (this.$refs.colibrioPublicationSettingsDrawer) {
      let drawer = ((this.$refs.colibrioPublicationSettingsDrawer as Vue).$el as HTMLElement);
      if (drawer) {
        drawer.style.visibility = 'visible';
        this.appModalComponentActive = true;
        this.settingsDrawerFocusTrap = this.settingsDrawerFocusTrap || createFocusTrap(drawer, {
          clickOutsideDeactivates: true,
          escapeDeactivates: true,
          returnFocusOnDeactivate: false
        });
        window.setTimeout(() => {
          this.settingsDrawerFocusTrap.activate();
          drawer.focus();
        }, 500);
      }
    }
  }

  toggleFullScreen() {
    //let inFullscreenState = (document.fullscreenElement !== null || document.webkitFullscreenElement !== null) || Math.abs(window.innerHeight - screen.height) <= 3;
    if (document.documentElement.requestFullscreen) {
      if (document.fullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      } else {
        document.documentElement.requestFullscreen();
      }
    }
    // else if(document.documentElement.webkitRequestFullscreen) {
    //     if (document.webkitFullscreenElement) {
    //         if (document.webkitCancelFullScreen) {
    //             document.webkitCancelFullScreen();
    //         }
    //     } else {
    //         document.documentElement.webkitRequestFullscreen();
    //     }
    // }
  }

}
