/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.0.0
 */
'use strict';
(function (r, f) { if (typeof define === 'function' && define.amd) { define([], function () { return f(r); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(r); } else { r.colibrio = r.colibrio || {}; r.colibrio.polyfill = r.colibrio.polyfill || {}; r.colibrio.polyfill.es6 = f(r); } }(typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function(__self) { var api = {}; (function() { 'use strict';var h="function"==typeof Object.defineProperties?Object.defineProperty:function(b,a,c){b!=Array.prototype&&b!=Object.prototype&&(b[a]=c.value)},p="undefined"!=typeof window&&window===this?this:"undefined"!=typeof global&&null!=global?global:this;function t(b,a){if(a){var c=p;b=b.split(".");for(var d=0;d<b.length-1;d++){var e=b[d];e in c||(c[e]={});c=c[e]}b=b[b.length-1];d=c[b];a=a(d);a!=d&&null!=a&&h(c,b,{configurable:!0,writable:!0,value:a})}}
t("Array.prototype.copyWithin",function(b){function a(c){c=Number(c);return Infinity===c||-Infinity===c?c:c|0}return b?b:function(c,d,e){var g=this.length;c=a(c);d=a(d);e=void 0===e?g:a(e);c=0>c?Math.max(g+c,0):Math.min(c,g);d=0>d?Math.max(g+d,0):Math.min(d,g);e=0>e?Math.max(g+e,0):Math.min(e,g);if(c<d)for(;d<e;)d in this?this[c++]=this[d++]:(delete this[c++],d++);else for(e=Math.min(e,g+d-c),c+=e-d;e>d;)--e in this?this[--c]=this[e]:delete this[--c];return this}});
function u(b){var a=0;return function(){return a<b.length?{done:!1,value:b[a++]}:{done:!0}}}function w(){w=function(){};p.Symbol||(p.Symbol=x)}function y(b,a){this.a=b;h(this,"description",{configurable:!0,writable:!0,value:a})}y.prototype.toString=function(){return this.a};var x=function(){function b(c){if(this instanceof b)throw new TypeError("Symbol is not a constructor");return new y("jscomp_symbol_"+(c||"")+"_"+a++,c)}var a=0;return b}();
function z(){w();var b=p.Symbol.iterator;b||(b=p.Symbol.iterator=p.Symbol("Symbol.iterator"));"function"!=typeof Array.prototype[b]&&h(Array.prototype,b,{configurable:!0,writable:!0,value:function(){return C(u(this))}});z=function(){}}function C(b){z();b={next:b};b[p.Symbol.iterator]=function(){return this};return b}
function D(b,a){z();b instanceof String&&(b+="");var c=0,d={next:function(){if(c<b.length){var e=c++;return{value:a(e,b[e]),done:!1}}d.next=function(){return{done:!0,value:void 0}};return d.next()}};d[Symbol.iterator]=function(){return d};return d}t("Array.prototype.entries",function(b){return b?b:function(){return D(this,function(a,c){return[a,c]})}});
t("Array.prototype.fill",function(b){return b?b:function(a,c,d){var e=this.length||0;0>c&&(c=Math.max(0,e+c));if(null==d||d>e)d=e;d=Number(d);0>d&&(d=Math.max(0,e+d));for(c=Number(c||0);c<d;c++)this[c]=a;return this}});function E(b,a,c){b instanceof String&&(b=String(b));for(var d=b.length,e=0;e<d;e++){var g=b[e];if(a.call(c,g,e,b))return{l:e,s:g}}return{l:-1,s:void 0}}t("Array.prototype.find",function(b){return b?b:function(a,c){return E(this,a,c).s}});
t("Array.prototype.findIndex",function(b){return b?b:function(a,c){return E(this,a,c).l}});t("Array.prototype.flat",function(b){return b?b:function(a){a=void 0===a?1:a;for(var c=[],d=0;d<this.length;d++){var e=this[d];Array.isArray(e)&&0<a?(e=Array.prototype.flat.call(e,a-1),c.push.apply(c,e)):c.push(e)}return c}});t("Array.prototype.flatMap",function(b){return b?b:function(a,c){for(var d=[],e=0;e<this.length;e++){var g=a.call(c,this[e],e,this);Array.isArray(g)?d.push.apply(d,g):d.push(g)}return d}});
t("Array.from",function(b){return b?b:function(a,c,d){c=null!=c?c:function(f){return f};var e=[],g="undefined"!=typeof Symbol&&Symbol.iterator&&a[Symbol.iterator];if("function"==typeof g){a=g.call(a);for(var k=0;!(g=a.next()).done;)e.push(c.call(d,g.value,k++))}else for(g=a.length,k=0;k<g;k++)e.push(c.call(d,a[k],k));return e}});t("Object.is",function(b){return b?b:function(a,c){return a===c?0!==a||1/a===1/c:a!==a&&c!==c}});
t("Array.prototype.includes",function(b){return b?b:function(a,c){var d=this;d instanceof String&&(d=String(d));var e=d.length;c=c||0;for(0>c&&(c=Math.max(c+e,0));c<e;c++){var g=d[c];if(g===a||Object.is(g,a))return!0}return!1}});t("Array.prototype.keys",function(b){return b?b:function(){return D(this,function(a){return a})}});t("Array.of",function(b){return b?b:function(a){return Array.from(arguments)}});t("Array.prototype.values",function(b){return b?b:function(){return D(this,function(a,c){return c})}});
function F(b){var a="undefined"!=typeof Symbol&&Symbol.iterator&&b[Symbol.iterator];return a?a.call(b):{next:u(b)}}
t("Promise",function(b){function a(k){this.c=0;this.i=void 0;this.a=[];var f=this.g();try{k(f.resolve,f.reject)}catch(l){f.reject(l)}}function c(){this.a=null}function d(k){return k instanceof a?k:new a(function(f){f(k)})}if(b)return b;c.prototype.c=function(k){if(null==this.a){this.a=[];var f=this;this.g(function(){f.i()})}this.a.push(k)};var e=p.setTimeout;c.prototype.g=function(k){e(k,0)};c.prototype.i=function(){for(;this.a&&this.a.length;){var k=this.a;this.a=[];for(var f=0;f<k.length;++f){var l=
k[f];k[f]=null;try{l()}catch(m){this.h(m)}}}this.a=null};c.prototype.h=function(k){this.g(function(){throw k;})};a.prototype.g=function(){function k(m){return function(n){l||(l=!0,m.call(f,n))}}var f=this,l=!1;return{resolve:k(this.A),reject:k(this.h)}};a.prototype.A=function(k){if(k===this)this.h(new TypeError("A Promise cannot resolve to itself"));else if(k instanceof a)this.B(k);else{a:switch(typeof k){case "object":var f=null!=k;break a;case "function":f=!0;break a;default:f=!1}f?this.w(k):this.m(k)}};
a.prototype.w=function(k){var f=void 0;try{f=k.then}catch(l){this.h(l);return}"function"==typeof f?this.C(f,k):this.m(k)};a.prototype.h=function(k){this.o(2,k)};a.prototype.m=function(k){this.o(1,k)};a.prototype.o=function(k,f){if(0!=this.c)throw Error("Cannot settle("+k+", "+f+"): Promise already settled in state"+this.c);this.c=k;this.i=f;this.v()};a.prototype.v=function(){if(null!=this.a){for(var k=0;k<this.a.length;++k)g.c(this.a[k]);this.a=null}};var g=new c;a.prototype.B=function(k){var f=this.g();
k.j(f.resolve,f.reject)};a.prototype.C=function(k,f){var l=this.g();try{k.call(f,l.resolve,l.reject)}catch(m){l.reject(m)}};a.prototype.then=function(k,f){function l(q,v){return"function"==typeof q?function(A){try{m(q(A))}catch(B){n(B)}}:v}var m,n,r=new a(function(q,v){m=q;n=v});this.j(l(k,m),l(f,n));return r};a.prototype.catch=function(k){return this.then(void 0,k)};a.prototype.j=function(k,f){function l(){switch(m.c){case 1:k(m.i);break;case 2:f(m.i);break;default:throw Error("Unexpected state: "+
m.c);}}var m=this;null==this.a?g.c(l):this.a.push(l)};a.resolve=d;a.reject=function(k){return new a(function(f,l){l(k)})};a.race=function(k){return new a(function(f,l){for(var m=F(k),n=m.next();!n.done;n=m.next())d(n.value).j(f,l)})};a.all=function(k){var f=F(k),l=f.next();return l.done?d([]):new a(function(m,n){function r(A){return function(B){q[A]=B;v--;0==v&&m(q)}}var q=[],v=0;do q.push(void 0),v++,d(l.value).j(r(q.length-1),n),l=f.next();while(!l.done)})};return a});
t("Promise.prototype.finally",function(b){return b?b:function(a){return this.then(function(c){return Promise.resolve(a()).then(function(){return c})},function(c){return Promise.resolve(a()).then(function(){throw c;})})}});var G;if("function"==typeof Object.setPrototypeOf)G=Object.setPrototypeOf;else{var H;a:{var I={u:!0},J={};try{J.__proto__=I;H=J.u;break a}catch(b){}H=!1}G=H?function(b,a){b.__proto__=a;if(b.__proto__!==a)throw new TypeError(b+" is not extensible");return b}:null}var K=G;
function L(b,a){return Object.prototype.hasOwnProperty.call(b,a)}
t("WeakMap",function(b){function a(f){this.a=(k+=Math.random()+1).toString();if(f){f=F(f);for(var l;!(l=f.next()).done;)l=l.value,this.set(l[0],l[1])}}function c(){}function d(f){if(!L(f,g)){var l=new c;h(f,g,{value:l})}}function e(f){var l=Object[f];l&&(Object[f]=function(m){if(m instanceof c)return m;d(m);return l(m)})}if(function(){if(!b||!Object.seal)return!1;try{var f=Object.seal({}),l=Object.seal({}),m=new b([[f,2],[l,3]]);if(2!=m.get(f)||3!=m.get(l))return!1;m.delete(f);m.set(l,4);return!m.has(f)&&
4==m.get(l)}catch(n){return!1}}())return b;var g="$jscomp_hidden_"+Math.random();e("freeze");e("preventExtensions");e("seal");var k=0;a.prototype.set=function(f,l){d(f);if(!L(f,g))throw Error("WeakMap key fail: "+f);f[g][this.a]=l;return this};a.prototype.get=function(f){return L(f,g)?f[g][this.a]:void 0};a.prototype.has=function(f){return L(f,g)&&L(f[g],this.a)};a.prototype.delete=function(f){return L(f,g)&&L(f[g],this.a)?delete f[g][this.a]:!1};return a});
t("Map",function(b){function a(){var f={};return f.f=f.next=f.head=f}function c(f,l){var m=f.a;return C(function(){if(m){for(;m.head!=f.a;)m=m.f;for(;m.next!=m.head;)return m=m.next,{done:!1,value:l(m)};m=null}return{done:!0,value:void 0}})}function d(f,l){var m=l&&typeof l;"object"==m||"function"==m?g.has(l)?m=g.get(l):(m=""+ ++k,g.set(l,m)):m="p_"+l;var n=f.c[m];if(n&&L(f.c,m))for(f=0;f<n.length;f++){var r=n[f];if(l!==l&&r.key!==r.key||l===r.key)return{id:m,list:n,index:f,b:r}}return{id:m,list:n,
index:-1,b:void 0}}function e(f){this.c={};this.a=a();this.size=0;if(f){f=F(f);for(var l;!(l=f.next()).done;)l=l.value,this.set(l[0],l[1])}}if(function(){if(!b||"function"!=typeof b||!b.prototype.entries||"function"!=typeof Object.seal)return!1;try{var f=Object.seal({x:4}),l=new b(F([[f,"s"]]));if("s"!=l.get(f)||1!=l.size||l.get({x:4})||l.set({x:4},"t")!=l||2!=l.size)return!1;var m=l.entries(),n=m.next();if(n.done||n.value[0]!=f||"s"!=n.value[1])return!1;n=m.next();return n.done||4!=n.value[0].x||
"t"!=n.value[1]||!m.next().done?!1:!0}catch(r){return!1}}())return b;z();var g=new WeakMap;e.prototype.set=function(f,l){f=0===f?0:f;var m=d(this,f);m.list||(m.list=this.c[m.id]=[]);m.b?m.b.value=l:(m.b={next:this.a,f:this.a.f,head:this.a,key:f,value:l},m.list.push(m.b),this.a.f.next=m.b,this.a.f=m.b,this.size++);return this};e.prototype.delete=function(f){f=d(this,f);return f.b&&f.list?(f.list.splice(f.index,1),f.list.length||delete this.c[f.id],f.b.f.next=f.b.next,f.b.next.f=f.b.f,f.b.head=null,
this.size--,!0):!1};e.prototype.clear=function(){this.c={};this.a=this.a.f=a();this.size=0};e.prototype.has=function(f){return!!d(this,f).b};e.prototype.get=function(f){return(f=d(this,f).b)&&f.value};e.prototype.entries=function(){return c(this,function(f){return[f.key,f.value]})};e.prototype.keys=function(){return c(this,function(f){return f.key})};e.prototype.values=function(){return c(this,function(f){return f.value})};e.prototype.forEach=function(f,l){for(var m=this.entries(),n;!(n=m.next()).done;)n=
n.value,f.call(l,n[1],n[0],this)};e.prototype[Symbol.iterator]=e.prototype.entries;var k=0;return e});t("Math.acosh",function(b){return b?b:function(a){a=Number(a);return Math.log(a+Math.sqrt(a*a-1))}});t("Math.asinh",function(b){return b?b:function(a){a=Number(a);if(0===a)return a;var c=Math.log(Math.abs(a)+Math.sqrt(a*a+1));return 0>a?-c:c}});
t("Math.log1p",function(b){return b?b:function(a){a=Number(a);if(.25>a&&-.25<a){for(var c=a,d=1,e=a,g=0,k=1;g!=e;)c*=a,k*=-1,e=(g=e)+k*c/++d;return e}return Math.log(1+a)}});t("Math.atanh",function(b){if(b)return b;var a=Math.log1p;return function(c){c=Number(c);return(a(c)-a(-c))/2}});t("Math.cbrt",function(b){return b?b:function(a){if(0===a)return a;a=Number(a);var c=Math.pow(Math.abs(a),1/3);return 0>a?-c:c}});
t("Math.clz32",function(b){return b?b:function(a){a=Number(a)>>>0;if(0===a)return 32;var c=0;0===(a&4294901760)&&(a<<=16,c+=16);0===(a&4278190080)&&(a<<=8,c+=8);0===(a&4026531840)&&(a<<=4,c+=4);0===(a&3221225472)&&(a<<=2,c+=2);0===(a&2147483648)&&c++;return c}});t("Math.cosh",function(b){if(b)return b;var a=Math.exp;return function(c){c=Number(c);return(a(c)+a(-c))/2}});
t("Math.expm1",function(b){return b?b:function(a){a=Number(a);if(.25>a&&-.25<a){for(var c=a,d=1,e=a,g=0;g!=e;)c*=a/++d,e=(g=e)+c;return e}return Math.exp(a)-1}});t("Math.fround",function(b){if(b)return b;if("function"!==typeof Float32Array)return function(c){return c};var a=new Float32Array(1);return function(c){a[0]=c;return a[0]}});
t("Math.hypot",function(b){return b?b:function(a){if(2>arguments.length)return arguments.length?Math.abs(arguments[0]):0;var c,d,e;for(c=e=0;c<arguments.length;c++)e=Math.max(e,Math.abs(arguments[c]));if(1E100<e||1E-100>e){if(!e)return e;for(c=d=0;c<arguments.length;c++){var g=Number(arguments[c])/e;d+=g*g}return Math.sqrt(d)*e}for(c=d=0;c<arguments.length;c++)g=Number(arguments[c]),d+=g*g;return Math.sqrt(d)}});
t("Math.imul",function(b){return b?b:function(a,c){a=Number(a);c=Number(c);var d=a&65535,e=c&65535;return d*e+((a>>>16&65535)*e+d*(c>>>16&65535)<<16>>>0)|0}});t("Math.log10",function(b){return b?b:function(a){return Math.log(a)/Math.LN10}});t("Math.log2",function(b){return b?b:function(a){return Math.log(a)/Math.LN2}});t("Math.sign",function(b){return b?b:function(a){a=Number(a);return 0===a||isNaN(a)?a:0<a?1:-1}});
t("Math.sinh",function(b){if(b)return b;var a=Math.exp;return function(c){c=Number(c);return 0===c?c:(a(c)-a(-c))/2}});t("Math.tanh",function(b){return b?b:function(a){a=Number(a);if(0===a)return a;var c=Math.exp(-2*Math.abs(a));c=(1-c)/(1+c);return 0>a?-c:c}});t("Math.trunc",function(b){return b?b:function(a){a=Number(a);if(isNaN(a)||Infinity===a||-Infinity===a||0===a)return a;var c=Math.floor(Math.abs(a));return 0>a?-c:c}});t("Number.EPSILON",function(){return Math.pow(2,-52)});
t("Number.MAX_SAFE_INTEGER",function(){return 9007199254740991});t("Number.MIN_SAFE_INTEGER",function(){return-9007199254740991});t("Number.isFinite",function(b){return b?b:function(a){return"number"!==typeof a?!1:!isNaN(a)&&Infinity!==a&&-Infinity!==a}});t("Number.isInteger",function(b){return b?b:function(a){return Number.isFinite(a)?a===Math.floor(a):!1}});t("Number.isNaN",function(b){return b?b:function(a){return"number"===typeof a&&isNaN(a)}});
t("Number.isSafeInteger",function(b){return b?b:function(a){return Number.isInteger(a)&&Math.abs(a)<=Number.MAX_SAFE_INTEGER}});t("Number.parseFloat",function(b){return b||parseFloat});t("Number.parseInt",function(b){return b||parseInt});var M="function"==typeof Object.assign?Object.assign:function(b,a){for(var c=1;c<arguments.length;c++){var d=arguments[c];if(d)for(var e in d)L(d,e)&&(b[e]=d[e])}return b};t("Object.assign",function(b){return b||M});
t("Object.entries",function(b){return b?b:function(a){var c=[],d;for(d in a)L(a,d)&&c.push([d,a[d]]);return c}});t("Object.fromEntries",function(b){return b?b:function(a){var c={};z();if(!(Symbol.iterator in a))throw new TypeError(""+a+" is not iterable");a=a[Symbol.iterator].call(a);for(var d=a.next();!d.done;d=a.next()){d=d.value;if(Object(d)!==d)throw new TypeError("iterable for fromEntries should yield objects");c[d[0]]=d[1]}return c}});
t("Object.getOwnPropertySymbols",function(b){return b?b:function(){return[]}});t("Reflect.ownKeys",function(b){return b?b:function(a){var c=[],d=Object.getOwnPropertyNames(a);a=Object.getOwnPropertySymbols(a);for(var e=0;e<d.length;e++)("jscomp_symbol_"==d[e].substring(0,14)?a:c).push(d[e]);return c.concat(a)}});t("Object.getOwnPropertyDescriptors",function(b){return b?b:function(a){for(var c={},d=Reflect.ownKeys(a),e=0;e<d.length;e++)c[d[e]]=Object.getOwnPropertyDescriptor(a,d[e]);return c}});
t("Object.setPrototypeOf",function(b){return b||K});t("Object.values",function(b){return b?b:function(a){var c=[],d;for(d in a)L(a,d)&&c.push(a[d]);return c}});t("Reflect.apply",function(b){if(b)return b;var a=Function.prototype.apply;return function(c,d,e){return a.call(c,d,e)}});
var N="function"==typeof Object.create?Object.create:function(b){function a(){}a.prototype=b;return new a},O=function(){function b(){function c(){}new c;Reflect.construct(c,[],function(){});return new c instanceof c}if("undefined"!=typeof Reflect&&Reflect.construct){if(b())return Reflect.construct;var a=Reflect.construct;return function(c,d,e){c=a(c,d);e&&Reflect.setPrototypeOf(c,e.prototype);return c}}return function(c,d,e){void 0===e&&(e=c);e=N(e.prototype||Object.prototype);return Function.prototype.apply.call(c,
e,d)||e}}();t("Reflect.construct",function(){return O});t("Reflect.defineProperty",function(b){return b?b:function(a,c,d){try{Object.defineProperty(a,c,d);var e=Object.getOwnPropertyDescriptor(a,c);return e?e.configurable===(d.configurable||!1)&&e.enumerable===(d.enumerable||!1)&&("value"in e?e.value===d.value&&e.writable===(d.writable||!1):e.get===d.get&&e.set===d.set):!1}catch(g){return!1}}});t("Reflect.deleteProperty",function(b){return b?b:function(a,c){if(!L(a,c))return!0;try{return delete a[c]}catch(d){return!1}}});
t("Reflect.getOwnPropertyDescriptor",function(b){return b||Object.getOwnPropertyDescriptor});t("Reflect.getPrototypeOf",function(b){return b||Object.getPrototypeOf});function P(b,a){for(;b;){var c=Reflect.getOwnPropertyDescriptor(b,a);if(c)return c;b=Reflect.getPrototypeOf(b)}}t("Reflect.get",function(b){return b?b:function(a,c,d){if(2>=arguments.length)return a[c];var e=P(a,c);if(e)return e.get?e.get.call(d):e.value}});t("Reflect.has",function(b){return b?b:function(a,c){return c in a}});
t("Reflect.isExtensible",function(b){return b?b:"function"==typeof Object.isExtensible?Object.isExtensible:function(){return!0}});t("Reflect.preventExtensions",function(b){return b?b:"function"!=typeof Object.preventExtensions?function(){return!1}:function(a){Object.preventExtensions(a);return!Object.isExtensible(a)}});
t("Reflect.set",function(b){return b?b:function(a,c,d,e){var g=P(a,c);return g?g.set?(g.set.call(3<arguments.length?e:a,d),!0):g.writable&&!Object.isFrozen(a)?(a[c]=d,!0):!1:Reflect.isExtensible(a)?(a[c]=d,!0):!1}});t("Reflect.setPrototypeOf",function(b){return b?b:K?function(a,c){try{return K(a,c),!0}catch(d){return!1}}:null});
t("Set",function(b){function a(c){this.a=new Map;if(c){c=F(c);for(var d;!(d=c.next()).done;)this.add(d.value)}this.size=this.a.size}if(function(){if(!b||"function"!=typeof b||!b.prototype.entries||"function"!=typeof Object.seal)return!1;try{var c=Object.seal({x:4}),d=new b(F([c]));if(!d.has(c)||1!=d.size||d.add(c)!=d||1!=d.size||d.add({x:4})!=d||2!=d.size)return!1;var e=d.entries(),g=e.next();if(g.done||g.value[0]!=c||g.value[1]!=c)return!1;g=e.next();return g.done||g.value[0]==c||4!=g.value[0].x||
g.value[1]!=g.value[0]?!1:e.next().done}catch(k){return!1}}())return b;z();a.prototype.add=function(c){c=0===c?0:c;this.a.set(c,c);this.size=this.a.size;return this};a.prototype.delete=function(c){c=this.a.delete(c);this.size=this.a.size;return c};a.prototype.clear=function(){this.a.clear();this.size=0};a.prototype.has=function(c){return this.a.has(c)};a.prototype.entries=function(){return this.a.entries()};a.prototype.values=function(){return this.a.values()};a.prototype.keys=a.prototype.values;
a.prototype[Symbol.iterator]=a.prototype.values;a.prototype.forEach=function(c,d){var e=this;this.a.forEach(function(g){return c.call(d,g,g,e)})};return a});function Q(b,a,c){if(null==b)throw new TypeError("The 'this' value for String.prototype."+c+" must not be null or undefined");if(a instanceof RegExp)throw new TypeError("First argument to String.prototype."+c+" must not be a regular expression");return b+""}
t("String.prototype.codePointAt",function(b){return b?b:function(a){var c=Q(this,null,"codePointAt"),d=c.length;a=Number(a)||0;if(0<=a&&a<d){a|=0;var e=c.charCodeAt(a);if(55296>e||56319<e||a+1===d)return e;a=c.charCodeAt(a+1);return 56320>a||57343<a?e:1024*(e-55296)+a+9216}}});t("String.prototype.endsWith",function(b){return b?b:function(a,c){var d=Q(this,a,"endsWith");void 0===c&&(c=d.length);c=Math.max(0,Math.min(c|0,d.length));for(var e=a.length;0<e&&0<c;)if(d[--c]!=a[--e])return!1;return 0>=e}});
t("String.fromCodePoint",function(b){return b?b:function(a){for(var c="",d=0;d<arguments.length;d++){var e=Number(arguments[d]);if(0>e||1114111<e||e!==Math.floor(e))throw new RangeError("invalid_code_point "+e);65535>=e?c+=String.fromCharCode(e):(e-=65536,c+=String.fromCharCode(e>>>10&1023|55296),c+=String.fromCharCode(e&1023|56320))}return c}});t("String.prototype.includes",function(b){return b?b:function(a,c){return-1!==Q(this,a,"includes").indexOf(a,c||0)}});
t("String.prototype.repeat",function(b){return b?b:function(a){var c=Q(this,null,"repeat");if(0>a||1342177279<a)throw new RangeError("Invalid count value");a|=0;for(var d="";a;)if(a&1&&(d+=c),a>>>=1)c+=c;return d}});function R(b,a){b=void 0!==b?String(b):" ";return 0<a&&b?b.repeat(Math.ceil(a/b.length)).substring(0,a):""}t("String.prototype.padEnd",function(b){return b?b:function(a,c){var d=Q(this,null,"padStart");return d+R(c,a-d.length)}});
t("String.prototype.padStart",function(b){return b?b:function(a,c){var d=Q(this,null,"padStart");return R(c,a-d.length)+d}});t("String.prototype.startsWith",function(b){return b?b:function(a,c){var d=Q(this,a,"startsWith"),e=d.length,g=a.length;c=Math.max(0,Math.min(c|0,d.length));for(var k=0;k<g&&c<e;)if(d[c++]!=a[k++])return!1;return k>=g}});t("String.prototype.trimRight",function(b){function a(){return this.replace(/[\s\xa0]+$/,"")}return b||a});
t("String.prototype.trimEnd",function(b){return b||String.prototype.trimRight});t("String.prototype.trimLeft",function(b){function a(){return this.replace(/^[\s\xa0]+/,"")}return b||a});t("String.prototype.trimStart",function(b){return b||String.prototype.trimLeft});
t("WeakSet",function(b){function a(c){this.a=new WeakMap;if(c){c=F(c);for(var d;!(d=c.next()).done;)this.add(d.value)}}if(function(){if(!b||!Object.seal)return!1;try{var c=Object.seal({}),d=Object.seal({}),e=new b([c]);if(!e.has(c)||e.has(d))return!1;e.delete(c);e.add(d);return!e.has(c)&&e.has(d)}catch(g){return!1}}())return b;a.prototype.add=function(c){this.a.set(c,!0);return this};a.prototype.has=function(c){return this.a.has(c)};a.prototype.delete=function(c){return this.a.delete(c)};return a});var S=[Int8Array,Uint8Array,Uint8ClampedArray,Int16Array,Uint16Array,Int32Array,Uint32Array,Float32Array,Float64Array];function T(b,a){var c=this.length;"number"!==typeof b&&(b=0);"number"!==typeof a&&(a=c);0>b&&(b=c+b);0>a&&(a=c+a);c=this.constructor;b=b*c.BYTES_PER_ELEMENT+this.byteOffset;a=a*c.BYTES_PER_ELEMENT+this.byteOffset;return new c(this.buffer.slice(b,a))}
if(!("slice"in S[0].prototype))for(var U=0;U<S.length;U++)Object.defineProperty(S[U].prototype,"slice",{configurable:!0,writable:!0,value:T}); }).call(__self); return api; }));
