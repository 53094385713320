
    import Component from "vue-class-component";
    import Vue from 'vue'
    import { Emit } from 'vue-property-decorator';

    declare var fileChooser: any;
    declare var FilePath: any;
    declare var Capacitor: any;

    @Component
    export default class ColibrioFileChooser extends Vue {
        epubUrl: string = 'http://';
        epubFileSize: string = '';
        epubUrlDialog: boolean = false;

        onSelectFile() {
            let fileInputElement = this.$refs.fileInput as HTMLElement;

            if( window.hasOwnProperty('Capacitor') && Capacitor.platform.toLowerCase() === 'android' ) {
                fileChooser.open(
                    (contentUri: any)=>{
                        FilePath.resolveNativePath(contentUri, (filePath: any) => {
                            Capacitor.Plugins.Filesystem.readFile({
                                    path: filePath
                                }).then((file: any)=>{
                                    file.data.indexOf();
                                    this.fileSelected(file.data);
                                });
                        }, (err: any)=>{
                            console.log(err)
                        });
                    },
                    (error: any)=>{
                        console.log(error)
                    }
                );
            }else{
                fileInputElement.click();
            }

        }

        onLoadUrl() {
            if (this.epubUrl) {
                let fileSize: number | undefined;
                if (this.epubFileSize) {
                    let parsedFileSize = parseInt(this.epubFileSize, 10);
                    if (!isNaN(parsedFileSize)) {
                        fileSize = parsedFileSize;
                    }
                }
                this.urlSelected(this.epubUrl, fileSize);
            }
        }

        onFileInputChanged(evt: Event) {
            let files = (evt.target as HTMLInputElement).files;
            if (files) {
                let file = files[0];
                this.fileSelected(file);
                // this.$emit('file-selected', file);
            }
        }

        onLogoClick() {
            //window.open('http://colibrio.com', '_blank');
        }

        onSamplePublicationsSelected(evt: Event) {
            let option = evt.srcElement as HTMLOptionElement;
            if(option && option.value) {
                document.location.href = '?publication_url=' + option.value;
            }
        }

        @Emit()
        fileSelected(_file: Blob | string) {
        }

        @Emit()
        urlSelected(_url: string, _fileSize?: number) {
        }
    }
