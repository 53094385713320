/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.0.0
 */
'use strict';
(function (r, f) { if (typeof define === 'function' && define.amd) { define([], function () { return f(r); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(r); } else { r.colibrio = r.colibrio || {}; r.colibrio.polyfill = r.colibrio.polyfill || {}; r.colibrio.polyfill.dom = f(r); } }(typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function(__self) { var api = {}; (function() { 'use strict';"undefined"!==typeof Element&&Element.prototype&&!Element.prototype.matches&&(Element.prototype.matches=Element.prototype.webkitMatchesSelector||function(b){b=this.ownerDocument.querySelectorAll(b);for(var a=b.length;0<=--a&&b[a]!==this;);return-1<a});function e(b){"children"in b.prototype||Object.defineProperty(b.prototype,"children",{get:function(){for(var a=0,c,d=this.childNodes,f=[];c=d[a++];)1===c.nodeType&&f.push(c);return f}})}
function g(b){"childElementCount"in b.prototype||Object.defineProperty(b.prototype,"childElementCount",{get:function(){for(var a=0,c=0,d,f=this.childNodes;d=f[a++];)1===d.nodeType&&c++;return c}})}
function h(b){"firstElementChild"in b.prototype||(Object.defineProperty(b.prototype,"firstElementChild",{get:function(){for(var a,c=this.childNodes,d=0;a=c[d++];)if(1===a.nodeType)return a;return null}}),Object.defineProperty(b.prototype,"lastElementChild",{get:function(){for(var a,c=this.childNodes,d=c.length-1;a=c[d--];)if(1===a.nodeType)return a;return null}}))}var k="undefined"!==typeof Element&&Element.prototype,l="undefined"!==typeof DocumentFragment&&DocumentFragment.prototype;
"undefined"!==typeof Document&&Document.prototype&&l&&k?(g(Element),e(Element),h(Element),g(Document),e(Document),h(Document),g(DocumentFragment),e(DocumentFragment),h(DocumentFragment)):typeof Node&&Node.prototype&&(e(Node),g(Node),h(Node)); }).call(__self); return api; }));
